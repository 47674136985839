import { connect } from 'react-redux'
import { createDate, storeActions } from '@morawadigital/skynet-framework'
import Schedule from '../../components/pages/Schedule'
import { setDateRange, setDivisions, setRegions, setScheduleGameFilterQuery, setScheduleLocalChanges, toggleAlternativeScheduleLayoutIsEnabled, toggleDisplayNotShowByDefaultRefereesIsEnabled, toggleDivisionDisplayIsEnabled, toggleScheduleHeaderIsHidden } from '../../store/actions'
import { AVAILABLE_REFEREES_SORT_BY_OPTION_NAME } from '../../util/constants'

const mapStateToProps = state => {

    return {

        alternativeScheduleLayoutIsEnabled:       state.ui.alternativeScheduleLayoutIsEnabled,
        availableRefereesFilterName:              state.ui.availableRefereesFilterName || '',
        availableRefereesFilterZipCode:           state.ui.availableRefereesFilterZipCode || '',
        availableRefereesSortBy:                  state.ui.availableRefereesSortBy || AVAILABLE_REFEREES_SORT_BY_OPTION_NAME,
        dateRangeFrom:                            createDate( state.ui.dateRangeFrom ),
        dateRangeTo:                              createDate( state.ui.dateRangeTo   ),
        displayNotShowByDefaultRefereesIsEnabled: state.ui.displayNotShowByDefaultRefereesIsEnabled || false,
        divisionDisplayIsEnabled:                 state.ui.divisionDisplayIsEnabled || false,
        divisions:                                state.ui.divisions || [],
        loading:                                  state.app.loading,
        localChanges:                             state.localChanges.schedule || {},
        regions:                                  state.ui.regions || [],
        scheduleGameFilterQuery:                  state.ui.scheduleGameFilterQuery || '',
        scheduleHeaderIsHidden:                   state.ui.scheduleHeaderIsHidden || false,
        token:                                    state.auth.token,
        user:                                     state.auth.user,

    }

}

const mapDispatchToProps = dispatch => {

    return {

        onDateRangeChange:                                ( from, to ) => dispatch( setDateRange(                                   from, to     ) ),
        onDivisionsChange:                                divisions    => dispatch( setDivisions(                                   divisions    ) ),
        onLocalChangesChange:                             localChanges => dispatch( setScheduleLocalChanges(                        localChanges ) ),
        onRegionsChange:                                  regions      => dispatch( setRegions(                                     regions      ) ),
        onScheduleGameFilterQueryChange:                  filterQuery  => dispatch( setScheduleGameFilterQuery(                     filterQuery  ) ),
        onToggleAlternativeScheduleLayoutIsEnabled:       isEnabled    => dispatch( toggleAlternativeScheduleLayoutIsEnabled(       isEnabled    ) ),
        onToggleDisplayNotShowByDefaultRefereesIsEnabled: isEnabled    => dispatch( toggleDisplayNotShowByDefaultRefereesIsEnabled( isEnabled    ) ),
        onToggleScheduleHeaderIsHidden:                   isHidden     => dispatch( toggleScheduleHeaderIsHidden(                   isHidden     ) ),
        onToggleLoading:                                  loading      => dispatch( storeActions.app.toggleLoading(                 loading      ) ),
        onToggleDivisionDisplayIsEnabled:                 isEnabled    => dispatch( toggleDivisionDisplayIsEnabled(                 isEnabled    ) ),

    }

}

export default connect( mapStateToProps, mapDispatchToProps )( Schedule )