// UI
export const SET_AVAILABLE_REFEREES_TOOLS_VALUES                        = 'SET_AVAILABLE_REFEREES_TOOLS_VALUES'
export const SET_DATE_RANGE                                             = 'SET_DATE_RANGE'
export const SET_DIVISIONS                                              = 'SET_DIVISIONS'
export const SET_EVALUATION_GAME_FILTER_QUERY                           = 'SET_EVALUATION_GAME_FILTER_QUERY'
export const SET_FILTER_LEAGUE_TYPE                                     = 'SET_FILTER_LEAGUE_TYPE'
export const SET_INVOICE_MANAGEMENT_FILTER_QUERY                        = 'SET_INVOICE_MANAGEMENT_FILTER_QUERY'
export const SET_LEAGUES_FILTER_QUERY                                   = 'SET_LEAGUES_FILTER_QUERY'
export const SET_NOTIFICATION_GAME_FILTER_QUERY                         = 'SET_NOTIFICATION_GAME_FILTER_QUERY'
export const SET_REGIONS                                                = 'SET_REGIONS'
export const SET_RELEASE_GAME_FILTER_QUERY                              = 'SET_RELEASE_GAME_FILTER_QUERY'
export const SET_SCHEDULE_GAME_FILTER_QUERY                             = 'SET_SCHEDULE_GAME_FILTER_QUERY'
export const SET_USERS_FILTER_DATE                                      = 'SET_USERS_FILTER_DATE'
export const SET_USERS_FILTER_QUERY                                     = 'SET_USERS_FILTER_QUERY'
export const TOGGLE_ALTERNATIVE_SCHEDULE_LAYOUT_IS_ENABLED              = 'TOGGLE_ALTERNATIVE_SCHEDULE_LAYOUT_IS_ENABLED'
export const TOGGLE_DISPLAY_NOT_SHOW_BY_DEFAULT_REFEREES_IS_ENABLED     = 'TOGGLE_DISPLAY_NOT_SHOW_BY_DEFAULT_REFEREES_IS_ENABLED'
export const TOGGLE_DIVISION_DISPLAY_IS_ENABLED                         = 'TOGGLE_DIVISION_DISPLAY_IS_ENABLED'
export const TOGGLE_SCHEDULE_HEADER_IS_HIDDEN                           = 'TOGGLE_SCHEDULE_HEADER_IS_HIDDEN'

// LOCAL CHANGES
export const SET_SCHEDULE_LOCAL_CHANGES                                 = 'SET_SCHEDULE_LOCAL_CHANGES'