import React from 'react'
import { ajax, getDataUrl, Icon, translate as _ } from '@morawadigital/skynet-framework'
import { Button, Col, Row, Toast, ToastContainer } from 'react-bootstrap'
import RegionRow from '../elements/RegionRow'
import { withRouter } from 'react-router-dom'
import { sortByName } from '../../util'

class RegionManagement extends React.Component {

    constructor( props ) {

        super( props )

        this.state = {

            hasLoadingError: false,
            regions:         null,

        }

    }

    componentDidMount() {

        this.load()

    }

    dismissLoadingError() {

        this.setState( { hasLoadingError: false } )

    }

    errorLoading() {

        this.setState( { hasLoadingError: true } )

    }

    load() {

        this.setState( { hasLoadingError: false }, () => setTimeout( () => {

            this.props.onToggleLoading( true )

            ajax( getDataUrl( 'api/Region/Get' ), { token: this.props.token }, { method: 'POST' } )
                .then( e => e.StatusId > 0 && e.Data ? this.setState( { regions: sortByName( e.Data ) } ) : this.errorLoading() )
                .catch( () => this.errorLoading() )
                .finally( () => this.props.onToggleLoading( false ) )

        }, 400 ) )

    }

    render() {

        return (

            <>

                <div className='subheader'>

                    <h1 className='subheader-title'>

                        <Icon icon='earth-europe' className='subheader-icon' /> { _( 'Regionsverwaltung' ) }

                    </h1>

                </div>

                <Row className='my-5'>

                    <Col>

                        {

                            this.props.loading ?

                                Array.from( { length: 3 } ).map( ( e, i ) => <RegionRow key={ i } /> )

                            : this.state.regions && ! this.state.regions.length ?

                                <div className='my-3 text-center'>{ _( 'Keine Regionen gefunden.' ) }</div>

                            : this.state.regions ?

                                this.state.regions.map( ( e, i ) => <RegionRow region={ e } key={ i } /> )

                            : ''

                        }

                    </Col>

                </Row>

                <ToastContainer position='bottom-center' containerPosition='fixed'>

                    <Toast onClose={ () => this.dismissLoadingError() } show={ this.state.hasLoadingError }>

                        <Toast.Header>

                            <div className='flex-grow-1'><Icon icon='exclamation-triangle' /> { _( 'Fehler' ) }</div>

                        </Toast.Header>

                        <Toast.Body>

                            <p>{ _( 'Daten konnten nicht geladen werden.' ) }</p>

                            <Button onClick={ () => this.load() } variant='secondary'>{ _( 'Erneut versuchen' ) }</Button>

                        </Toast.Body>

                    </Toast>

                </ToastContainer>

            </>

        )

    }

}

export default withRouter( RegionManagement )