import { ajax, getDataUrl, Icon, translate as _ } from '@morawadigital/skynet-framework'
import React from 'react'
import { Button, Card, Col, Form, Row, Toast, ToastContainer } from 'react-bootstrap'
import { Link, withRouter } from 'react-router-dom'
import Select from 'react-select'
import { getRefereeFullName, sortByLastnameAndFirstname } from '../../util'
import { isScheduler, isSchedulerWithoutRelease } from '../../util/permissions'

class Region extends React.Component {

    #dom
    #timeIntervals

    constructor( props ) {

        super( props )

        this.state = {

            hasLoadingError:        false,
            hasSavingError:         false,
            isSaving:               false,
            region:                 null,
            managerIds:             [],
            referees:               null,
            showSaveSuccessMessage: false,

        }

    }

    componentDidMount() {

        this.load()

    }

    changeManagers( e ) {

        this.setState( { managerIds: e ? e.map( manager => manager.value ) : [] } )

    }

    dismissLoadingError() {

        this.setState( { hasLoadingError: false } )

    }

    dismissSaveSuccessMessage() {

        this.setState( { showSaveSuccessMessage: false } )

    }

    dismissSavingError() {

        this.setState( { hasSavingError: false } )

    }

    errorSaving() {

        this.setState( { hasSavingError: true } )

    }

    handleFormSubmit( e ) {

        e.preventDefault()

        this.saveRegion()

    }

    load() {

        this.setState( { hasLoadingError: false }, () => setTimeout( () => {

            this.props.onToggleLoading( true )

            const setData = ( e, resolve ) => {

                const region     = e[ 0 ]
                const managerIds = region.ManagerIds || []

                this.setState( { region, managerIds } )

                resolve()

            }

            const setReferees = ( e, resolve ) => {

                const referees = []

                sortByLastnameAndFirstname( e )

                e.forEach( referee => ( isScheduler( referee ) || isSchedulerWithoutRelease( referee ) ) && referees.push( { value: referee.Id, label: getRefereeFullName( referee ) } ) )

                this.setState( { referees } )

                resolve()

            }

            const itemsToLoad = [

                this.loadItems( { url: 'api/Region/Get',  data: { id: this.props.match.params.regionId }, success: setData     } ),
                this.loadItems( { url: 'api/Referee/Get', data: { shortResponse: true },                  success: setReferees } ),

            ]

            Promise
                .all( itemsToLoad )
                .catch( () => this.setState( { hasLoadingError: true } ) )
                .finally( () => this.props.onToggleLoading( false ) )

        }, 400 ) )

    }

    loadItems( options ) {

        let data = { token: this.props.token }

        if ( options.data ) {

            data = { ...data, ...options.data }

        }

        return new Promise( ( resolve, reject ) => {

            ajax( getDataUrl( options.url ), data, { method: 'POST' } )
                .then( e => e.StatusId > 0 && e.Data ? options.success( e.Data, resolve ) : reject() )
                .catch( reject )

        } )

    }

    saveRegion() {

        this.setState( { hasSavingError: false, isSaving: true, showSaveSuccessMessage: false }, () => {

            const data = {

                regionId:   this.state.region.Id,
                managerIds: { __arr: true, values: this.state.managerIds || [] },
                token:      this.props.token,

            }

            this.props.onToggleLoading( true )

            ajax( getDataUrl( 'api/Region/MapRegionReferee' ), data, { method: 'POST' } )
                .then( e => e.StatusId > 0 && e.Data ? this.setState( { showSaveSuccessMessage: true }, () => setTimeout( () => this.state.showSaveSuccessMessage && this.setState( { showSaveSuccessMessage: false } ), 3000 ) ) : this.errorSaving() )
                .catch( () => this.errorSaving() )
                .finally( () => this.setState( { isSaving: false }, () => this.props.onToggleLoading( false ) ) )

        } )

    }

    render() {

        const formDisabled = this.props.loading || this.state.hasLoadingError || this.state.isSaving
        const managers     = this.state.referees ? this.state.managerIds.map( managerId => {

            const referee = this.state.referees.find( e => e.value === managerId )

            return { value: managerId, label: referee ? referee.label : managerId }

        } ) : []

        return (

            <>

                <div className='subheader'>

                    <h1 className='subheader-title'>

                        <Icon icon='earth-europe' className='subheader-icon' /> { _( 'Region' ) } { this.state.region && <span className='fw-300'>{ this.state.region.Name }</span>}

                    </h1>

                    <Link to='/regionsverwaltung' className='fs-3 ms-auto pe-3' role='button' title={ _( 'Schließen' ) }><Icon icon='times' /></Link>

                </div>

                <Card className='mb-2'>

                    <Form onSubmit={ e => this.handleFormSubmit( e ) }>

                        <fieldset disabled={ formDisabled }>

                            <Card.Header>

                                <Card.Title>{ _( 'Regionseinstellungen' ) }</Card.Title>

                            </Card.Header>

                            <Card.Body>

                                <Form.Group as={ Row } className='mb-3' controlId='managers'>

                                    <Form.Label column sm={ 4 }>{ _( 'Manager' ) }</Form.Label>

                                    <Col sm={ 8 }>

                                        <Select
                                            isDisabled={ formDisabled }
                                            isMulti
                                            noOptionsMessage={ () => _( 'Keine Optionen' ) }
                                            onChange={ e => this.changeManagers( e ) }
                                            options={ this.state.referees }
                                            placeholder=''
                                            value={ managers }
                                        />

                                    </Col>

                                </Form.Group>

                            </Card.Body>

                            <Card.Footer>

                                <Button type='submit'>{ _( 'Speichern' ) }</Button>

                            </Card.Footer>

                        </fieldset>

                    </Form>

                </Card>

                <ToastContainer position='bottom-center' containerPosition='fixed'>

                    <Toast onClose={ () => this.dismissLoadingError() } show={ this.state.hasLoadingError }>

                        <Toast.Header>

                            <div className='flex-grow-1'><Icon icon='exclamation-triangle' /> { _( 'Fehler' ) }</div>

                        </Toast.Header>

                        <Toast.Body>

                            <p>{ _( 'Daten konnten nicht geladen werden.' ) }</p>

                            <Button onClick={ () => this.load() } variant='secondary'>{ _( 'Erneut versuchen' ) }</Button>

                        </Toast.Body>

                    </Toast>

                    <Toast onClose={ () => this.dismissSavingError() } show={ this.state.hasSavingError }>

                        <Toast.Header>

                            <div className='flex-grow-1'><Icon icon='exclamation-triangle' /> { _( 'Fehler' ) }</div>

                        </Toast.Header>

                        <Toast.Body>

                            <p>{ _( 'Daten konnten nicht gespeichert werden.' ) }</p>

                        </Toast.Body>

                    </Toast>

                    <Toast onClose={ () => this.dismissSaveSuccessMessage() } show={ this.state.showSaveSuccessMessage }>

                        <Toast.Header>

                            <div className='flex-grow-1'></div>

                        </Toast.Header>

                        <Toast.Body>

                            <p><Icon icon='check' /> { _( 'Daten wurden gespeichert.' ) }</p>

                        </Toast.Body>

                    </Toast>

                </ToastContainer>

            </>

        )

    }

}

export default withRouter( Region )