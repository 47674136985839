import React from 'react'

class CardOverlay extends React.Component {

    render() {

        return (

            <div className='card-overlay'>

                { this.props.children }

            </div>

        )

    }

}

export default CardOverlay