import React from 'react'
import { ajax, getDataUrl, Icon, fillDate, translate as _, formatDate, formatTime, hasPrivilege, deepCopy, ajx } from '@morawadigital/skynet-framework'
import { withRouter } from 'react-router-dom'
import { Button, Col, FloatingLabel, Form, Row, Toast, ToastContainer } from 'react-bootstrap'
import TreeSelect from '../controls/TreeSelect'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import { convertLeagues, getDateRangePickerLocale, getRefereeFullName } from '../../util'
import EvaluationGameRow from '../elements/EvaluationGameRow'
import { getCustomer } from '../../util/customer'
import { PRIV_DELETE_EVALUATION } from '../../util/constants'

class Evaluation extends React.Component {

    #customer

    constructor( props ) {

        super( props )

        this.state = {

            divisions:       null,
            games:           null,
            gamesDeleting:   [],
            hasLoadingError: false,
            loadingGames:    false,
            selectedGames:   [],

        }

        this.#customer = getCustomer()

    }

    componentDidMount() {

        this.load()

    }

    deleteGame( gameId ) {

        ajx( {

            complete: () => this.setState( { gamesDeleting: this.state.gamesDeleting.filter( e => e !== gameId ) } ),
            data:     { gameId },
            error:    _( 'Evaluierung konnte nicht gelöscht werden.' ),
            success:  () => this.setState( { games: this.state.games.filter( e => e.Game.Id !== gameId ) } ),
            url:      'api/Coaching/deleteGameCoachings',

        } )

    }

    deleteGames() {

        if ( ! window.confirm( _( 'Spiel(e) löschen?' ) ) ) {

            return

        }

        this.setState( { gamesDeleting: [ ...this.state.selectedGames ], selectedGames: [] }, () => this.state.gamesDeleting.forEach( e => this.deleteGame( e ) ) )

    }

    dismissLoadingError() {

        this.setState( { hasLoadingError: false } )

    }

    errorLoading() {

        this.setState( { hasLoadingError: true } )

    }

    filterGame( game, coachResults ) {

        if ( ! this.props.filterQuery ) {

            return true

        }

        const filterQuery = this.props.filterQuery.toLowerCase()
        const values      = [

            game.LeagueName,

            formatDate( game.ScheduleDate, { year: 'none', weekday: 'short' } ),
            formatTime( game.ScheduleDate ),

        ]

        this.#customer.displayGameName && values.push( game.Name )

        game.AwayTeam && values.push( game.AwayTeam.Name, game.AwayTeam.ShortName )
        game.HomeTeam && values.push( game.HomeTeam.Name, game.HomeTeam.ShortName )

        if ( game.Location ) {

            if ( this.#customer.useLocationShortName ) {

                values.push( game.Location.Shortname )

            } else {

                                         values.push( game.Location.Name         )
                game.Location.Address && values.push( game.Location.Address.City )

            }

        }

        if ( coachResults ) {

            coachResults.length && values.push( getRefereeFullName( coachResults[ 0 ].Coach ) )

            coachResults.forEach( e => values.push( getRefereeFullName( e.Referee ) ) )

        }

        for ( let i = 0; i < values.length; i++ ) {

            if ( values[ i ] && ( '' + values[ i ] ).toLowerCase().includes( filterQuery ) ) {

                return true

            }

        }

        return false

    }

    getFilteredGames() {

        return this.state.games && this.state.games.filter( e => this.filterGame( e.Game, e.CoachResults ) )

    }

    handleAllGamesSelect( e ) {

        const selectedGames = []
        const filteredGames = this.getFilteredGames()

        e.target.checked && filteredGames && filteredGames.forEach( e => selectedGames.push( e.Game.Id ) )

        this.setState( { selectedGames } )

    }

    handleDateRangeChange( e, f ) {

        this.props.onDateRangeChange(

            f.startDate ?           f.startDate.toDate().toJSON() : null,
            f.endDate   ? fillDate( f.endDate.toDate() ).toJSON() : null

        )

        setTimeout( () => this.loadGames(), 200 )

    }

    handleDivisionsSelect( e ) {

        this.props.onDivisionsChange( e )

        setTimeout( () => this.loadGames(), 200 )

    }

    handleFilterQueryChange( e ) {

        this.setState( { selectedGames: [] }, () => this.props.onFilterQueryChange( e ) )

    }

    handleGameSelect( e ) {

          let selectedGames = deepCopy( this.state.selectedGames )
        const gameId        = Number( e.target.value )
        const index         = selectedGames.indexOf( gameId )

        e.target.checked ? index === -1 && selectedGames.push( gameId ) : index !== -1 && selectedGames.splice( index, 1 )

        this.setState( { selectedGames } )

    }

    load() {

        this.setState( { hasLoadingError: false }, () => setTimeout( () => {

            this.props.onToggleLoading( true )

            ajax( getDataUrl( 'api/Schedule/getScheduleableLeagues' ), { token: this.props.token }, { method: 'POST' } )
                .then( e => e.StatusId > 0 && e.Data ? this.setState( { divisions: convertLeagues( e.Data ) }, () => this.loadGames() ) : this.errorLoading() )
                .catch( () => this.errorLoading() )
                .finally( () => this.props.onToggleLoading( false ) )

        }, 400 ) )

    }

    loadGames() {

        if ( ! this.props.dateRangeFrom || ! this.props.dateRangeTo || ! this.props.divisions.length ) {

            return

        }

        const data = {

            divIds: { __arr: true, values: this.props.divisions },
            from:   this.props.dateRangeFrom.toJSON(),
            to:     this.props.dateRangeTo.toJSON(),
            token:  this.props.token,

        }

        const success = games => {

            games.forEach( game => {

                game.HomeTeam = { Name: game.HomeTeam, ShortName: game.ShortHomeTeam }
                game.AwayTeam = { Name: game.AwayTeam, ShortName: game.ShortAwayTeam }

            } )

            this.setState( { games } )

        }

        this.setState( { hasLoadingError: false, loadingGames: true, selectedGames: [] }, () => setTimeout( () => {

            this.props.onToggleLoading( true )

            ajax( getDataUrl( 'api/Coaching/getCoachResults' ), data, { method: 'POST' } )
                .then( e => e.StatusId > 0 && e.Data ? success( e.Data ) : this.errorLoading() )
                .catch( () => this.errorLoading() )
                .finally( () => this.setState( { loadingGames: false }, () => this.props.onToggleLoading( false ) ) )

        }, 400 ) )

    }

    render() {

        const canDelete = hasPrivilege( this.props.user, PRIV_DELETE_EVALUATION )
        const games     = this.getFilteredGames()

        return (

            <>

                <div className='subheader'>

                    <h1 className='subheader-title'>

                        <Icon icon='list' className='subheader-icon' /> { _( 'Evaluierung' ) }

                    </h1>

                </div>

                <Form>

                    <Row className='mb-3'>

                        <Col className='mb-1' xs='6' md='3'>

                            <FloatingLabel label={ _( 'Liga' ) }>

                                <TreeSelect options={ this.state.divisions } initialValue={ this.props.divisions } onChange={ e => this.handleDivisionsSelect( e ) } />

                            </FloatingLabel>

                        </Col>

                        <Col className='mb-1' xs='6' md='3'>

                            <FloatingLabel label={ _( 'Zeitraum' ) }>

                                <DateRangePicker initialSettings={ {

                                        endDate:   this.props.dateRangeTo,
                                        locale:    getDateRangePickerLocale(),
                                        startDate: this.props.dateRangeFrom,

                                    } } onApply={ ( e, f ) => this.handleDateRangeChange( e, f ) }>

                                    <Form.Control />

                                </DateRangePicker>

                            </FloatingLabel>

                        </Col>

                        <Col className='mb-1' xs='12' md='6'>

                            <FloatingLabel label={ _( 'Spiele suchen' ) }>

                                <Form.Control value={ this.props.filterQuery } onChange={ e => this.handleFilterQueryChange( e.target.value ) } />

                            </FloatingLabel>

                        </Col>

                    </Row>

                    { canDelete &&

                        <Row className='align-items-center mb-3 px-3'>

                            <Col className='mb-1'>

                                <Form.Check inline disabled={ ! games || ! games.length } label={ _( 'Alle' ) } onChange={ e => this.handleAllGamesSelect( e ) } checked={ games && games.length ? this.state.selectedGames.length === games.length : false } />

                            </Col>

                            <Col className='mb-1 text-end'>

                                <Button onClick={ () => this.deleteGames() } disabled={ ! this.state.selectedGames.length || this.state.gamesDeleting.length }>{ _( 'Auswahl löschen' ) }</Button>

                            </Col>

                        </Row>

                    }

                </Form>

                {

                    this.state.loadingGames ?

                        Array.from( { length: 3 } ).map( ( e, i ) => <EvaluationGameRow key={ i } /> )

                    : games && ! games.length ?

                        <div className='my-3 text-center'>{ _( 'Keine Spiele gefunden.' ) }</div>

                    : games ?

                        <div>{ games.map( ( game, i ) =>

                            <EvaluationGameRow
                                game={ game }
                                isSelected={ this.state.selectedGames.indexOf( game.Game.Id ) !== -1 }
                                key={ i }
                                onSelectGame={ canDelete ? e => this.handleGameSelect( e ) : null }
                                overlay={ this.state.gamesDeleting.indexOf( game.Game.Id ) !== -1 && _( 'Wird gelöscht...' ) }
                            />

                        ) }</div>

                    : ''

                }

                <ToastContainer position='bottom-center' containerPosition='fixed'>

                    <Toast onClose={ () => this.dismissLoadingError() } show={ this.state.hasLoadingError }>

                        <Toast.Header>

                            <div className='flex-grow-1'><Icon icon='exclamation-triangle' /> { _( 'Fehler' ) }</div>

                        </Toast.Header>

                        <Toast.Body>

                            <p>{ _( 'Daten konnten nicht geladen werden.' ) }</p>

                            <Button onClick={ () => this.load() } variant='secondary'>{ _( 'Erneut versuchen' ) }</Button>

                        </Toast.Body>

                    </Toast>

                </ToastContainer>

            </>

        )

    }

}

export default withRouter( Evaluation )