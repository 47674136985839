import React from 'react'
import { formatDate, translate as _ } from '@morawadigital/skynet-framework'
import { Card, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import RefereeBadge from './RefereeBadge'
import { getShortName } from '../../util'

class GameInfoCard extends React.Component {

    renderGameRow( game, i ) {

        const league = game.league ? game.league : game.leagueLongName ? getShortName( game.leagueLongName ) : null

        return (

            <Row className='mb-1' key={ i }>

                <Col>{ formatDate( game.scheduledDate, { year: 'none', weekday: 'short' } ) }</Col>

                { this.props.neededReferees && this.props.neededReferees.map( ( e, i ) => <Col key={ i }>{ this.renderReferee( game, e ) }</Col> ) }

                <Col>{ this.renderTeam( game.homeTeam, game.homeTeamLongName ) }</Col>
                <Col>{ this.renderTeam( game.awayTeam, game.awayTeamLongName ) }</Col>

                { this.props.showLeagueColumn &&

                    <Col>{

                        game.leagueLongName ?

                            <OverlayTrigger overlay={ <Tooltip>{ game.leagueLongName }</Tooltip> }>

                                <span>{ league }</span>

                            </OverlayTrigger>

                        :

                            league

                    }</Col>

                }

            </Row>

        )

    }

    renderReferee( game, neededReferee ) {

        if ( ! game.referees ) {

            return

        }

        const assignment = game.referees.find( referee => referee.RefereeSystemJob.ShortName === neededReferee.RefereeSystemJob && referee.RefereeSystemJob.LicenseTypeId === neededReferee.RefereeSystemLicenseTypeId )
          let federation = null
          let referee    = null

        if ( assignment ) {

            if ( assignment.FederationId ) {

                federation = this.props.federations.find( e => e.Id === assignment.FederationId )

            } else if ( assignment.RefereeFirstName || assignment.RefereeLastName ) {

                referee = {

                    Firstname: assignment.RefereeFirstName,
                    Lastname:  assignment.RefereeLastName,

                }

            }

        }

        if ( federation || referee ) {

            return (

                <RefereeBadge
                    className='outline'
                    displayName={ federation ? federation.ShortName : null }
                    referee={ referee }
                    title={ federation && federation.Name }
                />

            )

        }

        return ''

    }

    renderTeam( shortName, longName ) {

        return (

            longName ?

                <OverlayTrigger overlay={ <Tooltip>{ longName }</Tooltip> }>

                    <span>{ shortName }</span>

                </OverlayTrigger>

            :

                shortName

        )

    }

    render() {

        return (

            <Card className={ 'mb-2 game-info-card text-center' + ( this.props.className ? ' ' + this.props.className : '' ) }>

                <Card.Header>{ this.props.title }</Card.Header>

                <Card.Body>

                    <Row className='border-bottom fw-700'>

                        <Col>{ _( 'Datum' ) }</Col>

                        { this.props.neededReferees && this.props.neededReferees.map( ( e, i ) => <Col key={ i }>{ e.RefereeSystemJob }</Col> ) }

                        <Col>{ _( 'Heim'  ) }</Col>
                        <Col>{ _( 'Gast'  ) }</Col>

                        { this.props.showLeagueColumn && <Col>{ _( 'Liga'  ) }</Col> }

                    </Row>

                    { this.props.games && <div className='games'>{ this.props.games.map( ( e, i ) => this.renderGameRow( e, i ) ) }</div> }

                </Card.Body>

            </Card>

        )

    }

}

export default GameInfoCard