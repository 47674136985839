import { connect } from 'react-redux'
import { createDate, storeActions } from '@morawadigital/skynet-framework'
import Users from '../../components/pages/Users'
import { setFilterLeagueType, setUsersFilterDate, setUsersFilterQuery } from '../../store/actions'

const mapStateToProps = state => {

    return {

        filterDate:       createDate( state.ui.usersFilterDate ),
        filterLeagueType: state.ui.filterLeagueType || '',
        filterQuery:      state.ui.usersFilterQuery || '',
        loading:          state.app.loading,
        token:            state.auth.token,

    }

}

const mapDispatchToProps = dispatch => {

    return {

        onSetFilterLeagueType: filterLeagueType => dispatch( setFilterLeagueType(            filterLeagueType ) ),
        onSetFilterDate:       filterDate       => dispatch( setUsersFilterDate(             filterDate       ) ),
        onSetFilterQuery:      filterQuery      => dispatch( setUsersFilterQuery(            filterQuery      ) ),
        onToggleLoading:       loading          => dispatch( storeActions.app.toggleLoading( loading          ) ),

    }

}

export default connect( mapStateToProps, mapDispatchToProps )( Users )