import React from 'react'
import { Icon, translate as _ } from '@morawadigital/skynet-framework'
import { withRouter } from 'react-router-dom'

class Dashboard extends React.Component {

    render() {

        return (

            <>

                <div className='subheader'>

                    <h1 className='subheader-title'>

                        <Icon icon='dashboard' className='subheader-icon' /> { _( 'Dashboard' ) }

                    </h1>

                </div>

            </>

        )

    }

}

export default withRouter( Dashboard )