import React from 'react'
import { ajax, getDataUrl, Icon, createDate, formatDateInput, translate as _ } from '@morawadigital/skynet-framework'
import { Button, Col, FloatingLabel, Form, Row, Toast, ToastContainer } from 'react-bootstrap'
import UserRow from '../elements/UserRow'
import ContactRow from '../elements/ContactRow'
import { sortByLastnameAndFirstname, sortByName } from '../../util'
import { Link } from 'react-router-dom'

class Users extends React.Component {

    #dataUrl
    #enableDateFilter
    #enableLeagueTypeFilter
    #rowComponent
    #showSubheaderBlock
    #text

    constructor( props ) {

        super( props )

        this.state = {

            hasLoadingError: false,
            leagueTypes:     null,
            users:           null,

        }

        this.#text = {}

        if ( 'contacts' in props ) {

            this.#dataUrl                     = 'api/Referee/GetContacts'
            this.#rowComponent                = ContactRow
            this.#text.filterQueryPlaceholder = _( 'Kontakte suchen' )
            this.#text.noneFound              = _( 'Keine Kontakte gefunden.' )
            this.#text.title                  = _( 'Kontakte' )

        } else if ( 'availableReferees' in props ) {

            this.#dataUrl                     = 'api/Federation/GetAvailableRefs'
            this.#enableDateFilter            = true
            this.#rowComponent                = ContactRow
            this.#text.filterQueryPlaceholder = _( 'Schiedsrichter:innen suchen' )
            this.#text.noneFound              = _( 'Keine Schiedsrichter:innen gefunden.' )
            this.#text.title                  = _( 'Verfügbare Schiedsrichter:innen' )

        } else {

            this.#dataUrl                     = 'api/Referee/Get'
            this.#enableLeagueTypeFilter      = true
            this.#rowComponent                = UserRow
            this.#showSubheaderBlock          = true
            this.#text.filterQueryPlaceholder = _( 'Benutzer:innen suchen' )
            this.#text.noneFound              = _( 'Keine Benutzer:innen gefunden.' )
            this.#text.title                  = _( 'Benutzer:innen' )

        }

    }

    componentDidMount() {

        this.load()

    }

    dismissLoadingError() {

        this.setState( { hasLoadingError: false } )

    }

    filterUser( user ) {

        const filterQuery = this.props.filterQuery.toLowerCase()

        if ( filterQuery && user.Lastname.toLowerCase().indexOf( filterQuery ) === -1 && user.Firstname.toLowerCase().indexOf( filterQuery ) === -1 ) {

            return false

        }

        if ( this.#enableLeagueTypeFilter && this.props.filterLeagueType ) {

            if ( ! user.MapLeagueReferees ) {

                return false

            }

            const filterLeagueType = Number( this.props.filterLeagueType )
              let found            = false

            user.MapLeagueReferees.forEach( e => {

                if ( e.LeagueType === filterLeagueType && e.Classifications.find( f => f.Category !== null ) ) {

                    found = true

                }

            } )

            return found

        }

        return true

    }

    handleFilterDateChange( e ) {

        this.props.onSetFilterDate( createDate( e ).toJSON() )

        setTimeout( () => this.load(), 200 )

    }

    load() {

        this.setState( { hasLoadingError: false }, () => setTimeout( () => {

            this.props.onToggleLoading( true )

            const itemsToLoad = [ this.loadItems( { url: this.#dataUrl, name: 'users', fn: sortByLastnameAndFirstname } ) ]

            this.#enableLeagueTypeFilter && itemsToLoad.push( this.loadItems( { url: 'api/LeagueType/Get', name: 'leagueTypes', fn: sortByName } ) )

            Promise
                .all( itemsToLoad )
                .catch( () => this.setState( { hasLoadingError: true } ) )
                .finally( () => this.props.onToggleLoading( false ) )

        }, 400 ) )

    }

    loadItems( options ) {

        return new Promise( ( resolve, reject ) => {

            const data = {

                shortResponse: true,
                token:         this.props.token,

            }

            if ( this.#enableDateFilter ) {

                data.date = this.props.filterDate.toJSON()

            }

            ajax( getDataUrl( options.url ), data, { method: 'POST' } )
                .then( e => e.StatusId > 0 && e.Data ? this.setState( { [ options.name ]: options.fn( e.Data ) }, resolve ) : reject() )
                .catch( reject )

        } )

    }

    render() {

        const RowComponent = this.#rowComponent
        const users = this.state.users ? this.state.users.filter( e => this.filterUser( e ) ) : null

        return (

            <>

                <div className='subheader'>

                    <h1 className='subheader-title'>

                        <Icon icon='users' className='subheader-icon' /> { this.#text.title }

                    </h1>

                    { this.#showSubheaderBlock &&

                        <div className='subheader-block'>

                            <Link to='/user/neu' className='btn btn-primary'><Icon icon='plus' /> { _( 'Neu' ) }</Link>

                        </div>

                    }

                </div>

                <Form>

                    <Row>

                        { this.#enableLeagueTypeFilter &&

                            <Col xs={ 12 } sm={ 6 } xl={ 5 } xxl={ 4 } className='mb-1'>

                                <FloatingLabel label={ _( 'Liga' ) }>

                                    <Form.Select value={ this.props.filterLeagueType } onChange={ e => this.props.onSetFilterLeagueType( e.target.value ) }>

                                        <option value=''>{ _( 'Alle' ) }</option>

                                        { this.state.leagueTypes && this.state.leagueTypes.map( ( e, i ) => <option value={ e.Id } key={ i }>{ e.Name }</option> ) }

                                    </Form.Select>

                                </FloatingLabel>

                            </Col>

                        }

                        { this.#enableDateFilter &&

                            <Col xs={ 12 } sm={ 6 } md={ 3 } className='mb-1'>

                                <FloatingLabel label={ _( 'Datum' ) }>

                                    <Form.Control type='date' value={ formatDateInput( this.props.filterDate ) } onChange={ e => this.handleFilterDateChange( e.target.value ) } />

                                </FloatingLabel>

                            </Col>

                        }

                        <Col xs={ 12 } sm={ 6 } xl={ 5 } xxl={ 4 } className='ms-auto mb-1'>

                            <Form.Group>

                                <Form.Control placeholder={ this.#text.filterQueryPlaceholder } size='lg' className='floating-label-height' value={ this.props.filterQuery } onChange={ e => this.props.onSetFilterQuery( e.target.value ) } />

                            </Form.Group>

                        </Col>

                    </Row>

                </Form>

                <Row className='my-5'>

                    <Col>

                        {

                            this.props.loading ?

                                Array.from( { length: 3 } ).map( ( e, i ) => <RowComponent key={ i } /> )

                            : users && ! users.length ?

                                <div className='my-3 text-center'>{ this.#text.noneFound }</div>

                            : users ?

                                users.map( ( user, i ) => <RowComponent contact={ user } user={ user } key={ i } /> )

                            : ''

                        }

                    </Col>

                </Row>

                <ToastContainer position='bottom-center' containerPosition='fixed'>

                    <Toast onClose={ () => this.dismissLoadingError() } show={ this.state.hasLoadingError }>

                        <Toast.Header>

                            <div className='flex-grow-1'><Icon icon='exclamation-triangle' /> { _( 'Fehler' ) }</div>

                        </Toast.Header>

                        <Toast.Body>

                            <p>{ _( 'Daten konnten nicht geladen werden.' ) }</p>

                            <Button onClick={ () => this.load() } variant='secondary'>{ _( 'Erneut versuchen' ) }</Button>

                        </Toast.Body>

                    </Toast>

                </ToastContainer>

            </>

        )

    }

}

export default Users