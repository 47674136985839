import React from 'react'
import { Icon, translate as _ } from '@morawadigital/skynet-framework'
import { Button, Card, Col, Dropdown, Placeholder, Row, SplitButton } from 'react-bootstrap'
import GameRowInfoColumn from './GameRowInfoColumn'
import GameRowRefereesColumn from './GameRowRefereesColumn'

class NotificationGameRow extends React.Component {

    render() {

        return (

            <Card className={ 'mb-2 notify-game-row' + ( this.props.game && this.props.game.isNotified ? ' notified' : '' ) }>

                <Card.Body>

                    <Row className='align-items-center'>

                        <Col xs={ 12 } xl={ 4 }>

                            <GameRowInfoColumn
                                game={ this.props.game }
                                isSelected={ this.props.isSelected }
                                onSelectGame={ e => this.props.onSelectGame( e ) }
                            />

                        </Col>

                        <Col xs={ 12 } xl={ 6 }>

                            <GameRowRefereesColumn
                                federations={ this.props.federations }
                                game={ this.props.game }
                                referees={ this.props.game && this.props.game.MapRefereeGames ? this.props.game.MapRefereeGames : [] }
                            />

                        </Col>

                        <Col xs={ 12 } xl={ 2 } className='text-end'>

                            {

                                this.props.game ? (

                                    this.props.canSendMessage ?

                                        <SplitButton
                                            align='end'
                                            disabled={ this.props.isNotifying || this.props.game.isNotified }
                                            onClick={ () => this.props.onNotifyGame( this.props.game.Id ) }
                                            title={ this.props.isNotifying ? <Icon spin icon='spinner' /> : this.props.game.isNotified ? <Icon icon='thumbs-up' /> : _( 'Versenden' ) }
                                        >

                                            <Dropdown.Item as='button' type='button' onClick={ () => this.props.onComposeMessage( this.props.game.Id ) }>{ _( 'Eigene Nachricht' ) }</Dropdown.Item>

                                        </SplitButton>

                                    :

                                        <Button disabled={ this.props.isNotifying || this.props.game.isNotified } onClick={ () => this.props.onNotifyGame( this.props.game.Id ) }>{ this.props.isNotifying ? <Icon spin icon='spinner' /> : this.props.game.isNotified ? <Icon icon='thumbs-up' /> : _( 'Versenden' ) }</Button>

                                ) :

                                    <Placeholder.Button xs={ 3 } />

                            }

                        </Col>

                    </Row>

                </Card.Body>

            </Card>

        )

    }

}

export default NotificationGameRow