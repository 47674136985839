import React from 'react'
import { Icon, translate as _ } from '@morawadigital/skynet-framework'
import { Alert, Button, ButtonGroup, Card, Col, Collapse, OverlayTrigger, Placeholder, Row, Tooltip } from 'react-bootstrap'
import RefereeBusinessCard from './RefereeBusinessCard'
import GameRowInfoColumn from './GameRowInfoColumn'
import GameRowRefereesColumn from './GameRowRefereesColumn'
import { ASSIGNMENT_STATUS_ACCEPTED, ASSIGNMENT_STATUS_DECLINED } from '../../util/constants'
import RefereeBadge from './RefereeBadge'
import { getCustomer } from '../../util/customer'

class MyScheduleGameRow extends React.Component {

    constructor( props ) {

        super( props )

        this.state = {

            infoOpen: false,

        }

    }

    getReferee( e ) {

        return e.RefereeId && ! e.FederationId ? {

            Id:               e.RefereeId,
            Firstname:        e.RefereeFirstName,
            Lastname:         e.RefereeLastName,
            RefereeSystemJob: e.RefereeSystemJob,
            assignment:       e,

        } : null

    }

    toggleInfo() {

        this.setState( { infoOpen: ! this.state.infoOpen } )

    }

    renderButton( onClick, variant, label, size ) {

        return <Button onClick={ onClick } disabled={ this.props.isProcessing } variant={ variant } size={ size || '' }>{ label }</Button>

    }

    renderReferee( assignment, options ) {

        if ( this.props.federationId && this.props.federationId === assignment.FederationId ) {

            const acceptButton  = <Button size='sm' variant='success' onClick={ () => this.props.onAcceptGame(  this.props.game.Id, assignment.Id ) } disabled={ assignment.Status === ASSIGNMENT_STATUS_ACCEPTED }>{ assignment.Status === ASSIGNMENT_STATUS_ACCEPTED ? _( 'Zugesagt' ) : <Icon icon='thumbs-up'   /> }</Button>
            const declineButton = <Button size='sm' variant='danger'  onClick={ () => this.props.onDeclineGame( this.props.game.Id, assignment.Id ) } disabled={ assignment.Status === ASSIGNMENT_STATUS_DECLINED }>{ assignment.Status === ASSIGNMENT_STATUS_DECLINED ? _( 'Abgesagt' ) : <Icon icon='thumbs-down' /> }</Button>

            return (

                <span className='referee-badge-container'>

                    { this.renderRefereeBadge( options ) }

                    <ButtonGroup>

                        { assignment.Status !== ASSIGNMENT_STATUS_ACCEPTED ? <OverlayTrigger overlay={ <Tooltip>{ _( 'Zusagen' ) }</Tooltip> }><span>{ acceptButton  }</span></OverlayTrigger> : acceptButton  }
                        { assignment.Status !== ASSIGNMENT_STATUS_DECLINED ? <OverlayTrigger overlay={ <Tooltip>{ _( 'Absagen' ) }</Tooltip> }><span>{ declineButton }</span></OverlayTrigger> : declineButton }

                    </ButtonGroup>

                </span>

            )

        } else {

            return this.renderRefereeBadge( options )

        }

    }

    renderRefereeBadge( e ) {

        return (

            <RefereeBadge
                className={ e.classes.join( ' ' ) }
                displayName={ e.displayName }
                label={ e.label }
                title={ e.title }
            />

        )

    }

    render() {

        const assignments  = this.props.game && this.props.game.MapRefereeGames ? this.props.game.MapRefereeGames : []
        const contacts     = []
        const gameNotes    = getCustomer().displayGameNotesInMySchedule && this.props.game && this.props.game.Note
          let myAssignment = null

        assignments.forEach( assignment => {

            const assignmentReferee = this.getReferee( assignment )
            const referee           = assignmentReferee && this.props.referees.find( e => e.Id === assignmentReferee.Id )

            if ( referee ) {

                if ( referee.Id === this.props.refereeId ) {

                    myAssignment = assignmentReferee.assignment

                } else {

                    contacts.push( { ...referee, assignment: assignmentReferee.RefereeSystemJob } )

                }

            }

        } )

        return (

            <Card className='mb-2'>

                <Card.Body className='p-0'>

                    <Row className='align-items-center'>

                        <Col xs={ 12 } xl={ 4 } className='px-5 py-3'>

                            <GameRowInfoColumn
                                game={ this.props.game }
                                isSelected={ this.props.isSelected }
                                onSelectGame={ e => this.props.onSelectGame( e ) }
                            />

                        </Col>

                        <Col xs={ 12 } xl={ this.props.game && ! myAssignment ? 8 : 7 } className='px-5'>

                            <GameRowRefereesColumn
                                federations={ this.props.federations }
                                game={ this.props.game }
                                referees={ this.props.game && this.props.game.MapRefereeGames ? this.props.game.MapRefereeGames : [] }
                                render={ ( e, f ) => this.renderReferee( e, f ) }
                            >

                                { ( contacts.length > 0 || gameNotes ) && <Button variant={ ( this.state.infoOpen ? '' : 'outline-' ) + 'info' } size='sm' className='my-2 py-1 me-1' onClick={ () => this.toggleInfo() }><Icon icon='info' /></Button> }

                                { this.props.canSendMessage && <OverlayTrigger overlay={ <Tooltip>{ _( 'Nachricht senden' ) }</Tooltip> }><Button variant='outline-secondary' size='sm' className='my-2 py-1' onClick={ () => this.props.onComposeMessage( this.props.game.Id ) }><Icon icon='envelope' /></Button></OverlayTrigger> }

                            </GameRowRefereesColumn>

                        </Col>

                        { ( ! this.props.game || myAssignment ) &&

                            <Col xs={ 12 } xl={ 1 }>

                                {

                                    this.props.game ?

                                        ( myAssignment.Status === ASSIGNMENT_STATUS_ACCEPTED ?

                                            <div className='feedback-status bg-success rounded-3'>

                                                <div className='fs-3'>

                                                    <Icon icon='thumbs-up' className='fs-3 mx-1 align-middle' />

                                                    <span className='fs-6 align-middle'>{ _( 'Zugesagt' ) }</span>

                                                </div>

                                                { this.renderButton( () => this.props.onDeclineGame( this.props.game.Id ), 'link', _( 'Absagen' ), 'sm' ) }

                                            </div>

                                        : myAssignment.Status === ASSIGNMENT_STATUS_DECLINED ?

                                            <div className='feedback-status bg-warning rounded-3'>

                                                <div className='fs-3'>

                                                    <Icon icon='thumbs-down' className='fs-3 mx-1 align-middle' />

                                                    <span className='fs-6 align-middle'>{ _( 'Abgesagt' ) }</span>

                                                </div>

                                                { this.renderButton( () => this.props.onAcceptGame( this.props.game.Id ), 'link', _( 'Zusagen' ), 'sm' ) }

                                            </div>

                                        :

                                            <div className='my-2 me-2'>

                                                <div className='text-end d-none d-lg-block'>

                                                    <ButtonGroup vertical>

                                                        { this.renderButton( () => this.props.onAcceptGame(  this.props.game.Id ), 'success', _( 'Zusagen' ) ) }
                                                        { this.renderButton( () => this.props.onDeclineGame( this.props.game.Id ), 'warning', _( 'Absagen' ) ) }

                                                    </ButtonGroup>

                                                </div>

                                                <div className='text-center d-block d-lg-none'>

                                                    { this.renderButton( () => this.props.onAcceptGame(  this.props.game.Id ), 'success', _( 'Zusagen' ) ) }
                                                    <span> </span>
                                                    { this.renderButton( () => this.props.onDeclineGame( this.props.game.Id ), 'warning', _( 'Absagen' ) ) }

                                                </div>

                                            </div>

                                        )

                                    :

                                        <div className='m-2'>

                                            <div className='text-end d-none d-lg-block'>

                                                <Placeholder.Button xs={ 12 } size='lg' animation='wave' />

                                            </div>

                                            <div className='text-center d-block d-lg-none'>

                                                <Placeholder.Button xs={ 4 } size='lg' animation='wave' />

                                            </div>

                                        </div>

                                }

                            </Col>

                        }

                    </Row>

                    <Collapse in={ this.state.infoOpen }>

                        <div className='game-info'>

                            { gameNotes && <Alert variant='info' className='m-3 mt-5'><Alert.Heading className='alert-icon-heading'><Icon icon='circle-info' /></Alert.Heading>{ gameNotes }</Alert> }

                            <div className='d-flex justify-content-center flex-wrap mt-3 pb-3'>

                                { contacts.map( ( e, i ) => <RefereeBusinessCard key={ i } referee={ e } /> ) }

                            </div>

                        </div>

                    </Collapse>

                </Card.Body>

            </Card>

        )

    }

}

export default MyScheduleGameRow