import React from 'react'
import { Card, Col, Placeholder, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { getCoachResultStatusIcon, getRefereeFullName } from '../../util'
import { coachResultStatus } from '../../util/enum'
import GameRowInfoColumn from './GameRowInfoColumn'
import RefereeBadge from './RefereeBadge'
import { translate as _ } from '@morawadigital/skynet-framework'
import CardOverlay from './CardOverlay'

class EvaluationGameRow extends React.Component {

    render() {

        const coach = this.props.game && this.props.game.CoachResults.length > 0 ? this.props.game.CoachResults[ 0 ].Coach : null

        return (

            <Card className='mb-2'>

                <Card.Body>

                    <Row className='align-items-center'>

                        <Col xs={ 12 } xl={ 5 }>

                            <GameRowInfoColumn
                                game={ this.props.game && this.props.game.Game }
                                isSelected={ this.props.isSelected }
                                noCheckbox={ ! this.props.onSelectGame }
                                onSelectGame={ e => this.props.onSelectGame( e ) }
                            />

                        </Col>

                        <Col xs={ 12 } xl={ 5 } className='py-3'>

                            {

                                this.props.game ?

                                    <div className='d-flex align-items-center flex-wrap'>

                                        { this.props.game.CoachResults.map( ( e, i ) =>

                                            <Link to={ '/evaluierung/' + e.Id } key={ i }>

                                                <RefereeBadge
                                                    className={ 'me-1 my-2 with-icon coach-result-status-' + e.Status }
                                                    displayName={ <>{ getCoachResultStatusIcon( e.Status ) } { getRefereeFullName( e.Referee ) }</> }
                                                    label={ e.RefereeSystemJob && e.RefereeSystemJob.Shortname }
                                                    title={ coachResultStatus[ e.Status ] }
                                                />

                                            </Link>

                                        ) }

                                    </div>

                                :

                                    <Placeholder animation='wave'><Placeholder xs={ 8 } /></Placeholder>

                            }

                        </Col>

                        <Col xs='12' xl='2'>

                            {

                                this.props.game ?

                                    ( coach &&

                                        <RefereeBadge
                                            className={ 'outline' + ( this.props.game.Edit ? ' fw-900' : '' ) }
                                            displayName={ getRefereeFullName( coach ) }
                                            label={ _( 'Coach' ) }
                                        />

                                    )

                                :

                                    <Placeholder animation='wave'><Placeholder xs={ 12 } /></Placeholder>

                            }

                        </Col>

                    </Row>

                </Card.Body>

                { this.props.overlay && <CardOverlay>{ this.props.overlay }</CardOverlay> }

            </Card>

        )

    }

}

export default EvaluationGameRow