// REACT_APP_EASYREF_CUSTOMER=deb npm start
// REACT_APP_EASYREF_CUSTOMER=deb npm run build

import icehockeyField from '../../img/icehockey-field.png'

const customers = {

    // AFBÖ
    afboe:

    {

        baseDataUrl:                                {

            'afboe-refereedev.hockeydata.net':      'https://backend-afboe-refereedev.hockeydata.net/',
            'afboe-easyref.morawa.digital':         'https://afboe-easyref.morawa.digital/data/',

        },
        devBaseDataUrl:                             'https://backend-afboe-refereedev.hockeydata.net/',
        disablePublicSchedule:                      true,
        displayGameName:                            true,
        groupAvailableRefereesByAvailability:       true,
        logoImageUrl:                               '/customer/afboe/logo.png',
        useLocationShortName:                       true,
        hiddenUserFields:                           [ 'passNumber', 'federationId', 'nationalFederation', 'refereeJerseySize', 'refereePantsSize', 'trainingJacketSize', 'trainingPantsSize', 'poloSize', 'jacketSize', 'shoeSize', 'headSize', 'mainFedRefSince', 'subFedRefSince' ],
        displayGameNotesInMySchedule:               true,
        disableGameInGameInfoCard:                  true,
        enableAlternativeScheduleLayoutByDefault:   true,
        hiddenScheduleUserFields:                   [ 'coachRating', 'league', 'allLeagues', 'refereeBans', 'refereeRelations' ],
        useCustomMessageForNotification:            false,

    },

    // DEB
    deb:

    {

        baseDataUrl:                                {

            'refereedev.hockeydata.net':            'https://refereedev.hockeydata.net/data/',
            'refereetest.hockeydata.net':           'https://refereetest.hockeydata.net/data/',
            'easyref.morawa.digital':               'https://easyref.morawa.digital/data/',
            'hessen-easyref.morawa.digital':        'https://hessen-easyref.morawa.digital/data/',
            'rlp-easyref.morawa.digital':           'https://rlp-easyref.morawa.digital/data/',

        },
        devBaseDataUrl:                             'https://refereedev.hockeydata.net/data/',
        // groupAvailableRefereesByAvailability:       true,
        logoImageUrl:                               '/customer/deb/logo.png',
        pageSidebarInfoCardBackgroundImage:         icehockeyField,

    },

    // IJS
    ijs:

    {

        baseDataUrl:                                {

            'ijs-easyref.morawa.digital':           'https://ijs-easyref.morawa.digital/data/',

        },
        devBaseDataUrl:                             'https://refereedev.hockeydata.net/data/',
        groupAvailableRefereesByAvailability:       true,
        logoImageUrl:                               '/customer/ijs/logo.png',
        hiddenUserFields:                           [ 'mainFedRefSince', 'subFedRefSince' ],

    },

    // MORAWA
    morawa:

    {

        devBaseDataUrl:                             'https://refereedev.hockeydata.net/data/',
        logoImageUrl:                               '/customer/morawa/logo.png',

    },

    // ÖEHV
    oeehv:

    {

        baseDataUrl:                                {

            'oeehv-refereedev.hockeydata.net':  'https://oeehv-refereedev.hockeydata.net/data/',

        },
        devBaseDataUrl:                             'https://oeehv-refereedev.hockeydata.net/data/',
        logoImageUrl:                               '/customer/oeehv/logo.png',
        useAlternativeRefereeShortname:             true,
        hiddenUserFields:                           [ 'mainFedRefSince', 'subFedRefSince' ],

    },

}

const defaultCustomerKey = 'morawa'
  let customerKey        = null

export const getCustomer = () => {

    return customers[ getCustomerKey() ]

}

export const getCustomerKey = () => {

    if ( customerKey ) {

        return customerKey

    }

    return customerKey = process.env.REACT_APP_EASYREF_CUSTOMER || defaultCustomerKey

}