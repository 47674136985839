import React from 'react'
import { Icon, constants, ajax, deepCopy, getDataUrl, hasPrivilege, translate as _ } from '@morawadigital/skynet-framework'
import { Button, Col, Dropdown, DropdownButton, Form, FormSelect, Modal, Row, Toast, ToastContainer } from 'react-bootstrap'
import { createMessageFromGame } from '../../util'
import MyScheduleGameRow from '../elements/MyScheduleGameRow'
import CalendarModal from '../../containers/elements/CalendarModal'
import { withRouter } from 'react-router-dom'

class MySchedule extends React.Component {

    constructor( props ) {

        super( props )

        this.state = {

            assignmentToDecline:     null,
            calendarModalOpen:       false,
            declineReason:           '',
            declineReasons:          null,
            error:                   null,
            federationId:            null,
            federations:             null,
            games:                   null,
            gamesProcessing:         [],
            gamesToDecline:          [],
            loading:                 false,
            refereeId:               null,
            referees:                [],
            selectedGames:           [],

        }

    }

    componentDidMount() {

        this.load()

    }

    changeDeclineReason( declineReason ) {

        this.setState( { declineReason } )

    }

    composeMessage( gameIds ) {

        this.props.onComposeMessage( createMessageFromGame( gameIds, this.state.games, this.props.user, this.state.refereeId ) )

    }

    declineGames() {

        const assignmentToDecline = this.state.assignmentToDecline
        const gameIds             = deepCopy( this.state.gamesToDecline )

        this.setState( { assignmentToDecline: null, gamesToDecline: [] }, () => this.processGames( gameIds, assignmentToDecline, 'DeclineGames', _( 'Absage nicht erfolgreich.' ), { declineReasonId: Number( this.state.declineReason ) } ) )

    }

    dismissError() {

        this.setState( { error: null } )

    }

    error( error ) {

        this.setState( { error } )

    }

    handleAllGamesSelect( e ) {

        const selectedGames = []

        e.target.checked && this.state.games && this.state.games.forEach( e => selectedGames.push( e.Id ) )

        this.setState( { selectedGames } )

    }

    handleGameSelect( e ) {

          let selectedGames = deepCopy( this.state.selectedGames )
        const gameId        = Number( e.target.value )
        const index         = selectedGames.indexOf( gameId )

        e.target.checked ? index === -1 && selectedGames.push( gameId ) : index !== -1 && selectedGames.splice( index, 1 )

        this.setState( { selectedGames } )

    }

    hideDeclineModal() {

        this.setState( { assignmentToDecline: null, gamesToDecline: [] } )

    }

    isSubfederation() {

        return ( 'isSubfederation' in this.props )

    }

    load() {

        this.setState( { error: null, loading: true }, () => setTimeout( () => {

            this.props.onToggleLoading( true )

            const itemsToLoad = [

                this.loadItems( { url: 'api/DeclineReason/GetReasons',                                                         name: 'declineReasons'                                                                                                                                                        } ),
                this.loadItems( { url: 'api/Referee/' + ( this.isSubfederation() ? 'GetLEVGameMappings' : 'GetGameMappings' ),                        success: ( e, resolve ) => this.setState( { federationId: e.FederationId, games: e.gameObjs, refereeId: e.RefereeId, referees: e.Referees }, resolve ) } ),
                this.loadItems( { url: 'api/Federation/Get',                                                                   name: 'federations'                                                                                                                                                           } ),

            ]

            Promise
                .all( itemsToLoad )
                .catch( () => this.error( _( 'Daten konnten nicht geladen werden.' ) ) )
                .finally( () => this.setState( { loading: false }, () => this.props.onToggleLoading( false ) ) )

        }, 400 ) )

    }

    loadItems( options ) {

        return new Promise( ( resolve, reject ) => {

            ajax( getDataUrl( options.url ), { token: this.props.token }, { method: 'POST' } )
                .then( e => e.StatusId > 0 && e.Data ? options.success ? options.success( e.Data[ 0 ], resolve ) : this.setState( { [ options.name ]: e.Data }, resolve ) : reject() )
                .catch( reject )

        } )

    }

    processGames( gameIds, assignmentId, action, error, additionalData ) {

        this.setState( { gamesProcessing: gameIds, error: null }, () => setTimeout( () => {

            this.props.onToggleLoading( true )

            const success = updatedGames => {

                const selectedGames = this.state.selectedGames.filter( e => this.state.gamesProcessing.indexOf( e ) === -1 )
                const games         = this.state.games && deepCopy( this.state.games ).map( game => updatedGames.find( updatedGame => updatedGame.Id === game.Id ) || game )

                this.setState( {

                    gamesProcessing: [],

                    games,
                    selectedGames,

                } )

            }

            const assignmentIds = []

            if ( assignmentId ) {

                assignmentIds.push( assignmentId )

            } else {

                gameIds.forEach( gameId => {

                    const game = this.state.games.find( game => game.Id === gameId )

                    game && game.MapRefereeGames && game.MapRefereeGames.forEach( e => ( this.state.federationId ? e.FederationId === this.state.federationId : e.RefereeId === this.state.refereeId ) && assignmentIds.push( e.Id ) )

                } )

            }

            let data = {

                mapIds: { __arr: true, values: assignmentIds },
                token: this.props.token,

            }

            if ( additionalData ) {

                data = { ...data, ...additionalData }

            }

            ajax( getDataUrl( 'api/Referee/' + action ), data, { method: 'POST' } )
                .then( e => e.StatusId > 0 ? success( e.Data ) : this.error( error ) )
                .catch( () => this.error( error ) )
                .finally( () => this.setState( { gamesProcessing: [] }, () => this.props.onToggleLoading( false ) ) )

        }, 400 ) )

    }

    showDeclineGamesModal( gamesToDecline, assignmentToDecline ) {

        this.setState( { declineReason: '', gamesToDecline, assignmentToDecline } )

    }

    toggleCalendarModal() {

        this.setState( { calendarModalOpen: ! this.state.calendarModalOpen } )

    }

    render() {

        const canSendMessage = hasPrivilege( this.props.user, constants.PRIV_SEND_MESSAGE ) || hasPrivilege( this.props.user, constants.PRIV_SEND_MESSAGE_WITH_READ_CONFIRMATION )

        return (

            <>

                <div className='subheader'>

                    <h1 className='subheader-title'>

                        <Icon icon='calendar' className='subheader-icon' /> { this.isSubfederation() ? _( 'LEV Einteilungen' ) : _( 'Meine Einteilungen' ) }

                    </h1>

                    <div className='subheader-block'>

                        <Button onClick={ () => this.toggleCalendarModal() } variant='secondary'><Icon icon='calendar' /> { _( 'Kalender' ) }</Button>

                    </div>

                </div>

                <Row className='my-5'>

                    <Col>

                        <Form>

                            <Row className='align-items-center mb-3 px-3'>

                                <Col>

                                    <Form.Check inline disabled={ ! this.state.games || ! this.state.games.length } label={ _( 'Alle' ) } onChange={ e => this.handleAllGamesSelect( e ) } checked={ this.state.games && this.state.games.length ? this.state.selectedGames.length === this.state.games.length : false } />

                                </Col>

                                <Col className='text-end'>

                                    <DropdownButton title={ _( 'Auswahl...' ) } disabled={ ! this.state.selectedGames.length || this.state.gamesProcessing.length > 0 } align='end'>

                                        <Dropdown.Item as='button' type='button' onClick={ () => this.processGames( this.state.selectedGames, null, 'AcceptGames', _( 'Zusage nicht erfolgreich.' ) ) }>{ _( 'Zusagen' ) }</Dropdown.Item>
                                        <Dropdown.Item as='button' type='button' onClick={ () => this.showDeclineGamesModal( this.state.selectedGames ) }>{ _( 'Absagen' ) }</Dropdown.Item>

                                        { canSendMessage && <Dropdown.Item as='button' type='button' onClick={ () => this.composeMessage( this.state.selectedGames ) }>{ _( 'Nachricht senden' ) }</Dropdown.Item> }

                                    </DropdownButton>

                                </Col>

                            </Row>

                        </Form>

                        {

                            this.state.loading ?

                                Array.from( { length: 3 } ).map( ( e, i ) => <MyScheduleGameRow key={ i } /> )

                            : this.state.games && ! this.state.games.length ?

                                <div className='my-3 text-center'>{ _( 'Keine Einteilungen gefunden.' ) }</div>

                            : this.state.games ?

                                <div>

                                    { this.state.games.map( ( game, i ) =>

                                        <MyScheduleGameRow
                                            canSendMessage={ canSendMessage }
                                            federationId={ this.state.federationId }
                                            federations={ this.state.federations }
                                            game={ game }
                                            isProcessing={ this.state.gamesProcessing.indexOf( game.Id ) !== -1 }
                                            isSelected={ this.state.selectedGames.indexOf( game.Id ) !== -1 }
                                            key={ i }
                                            onAcceptGame={ ( gameId, assignmentId ) => this.processGames( [ gameId ], assignmentId, 'AcceptGames', _( 'Zusage nicht erfolgreich.' ) ) }
                                            onComposeMessage={ e => this.composeMessage( [ e ] ) }
                                            onDeclineGame={ ( gameId, assignmentId ) => this.showDeclineGamesModal( [ gameId ], assignmentId ) }
                                            onSelectGame={ e => this.handleGameSelect( e ) }
                                            refereeId={ this.state.refereeId }
                                            referees={ this.state.referees }
                                            user={ this.props.user }
                                        />

                                    ) }

                                </div>

                            : ''

                        }

                    </Col>

                </Row>

                <Modal show={ this.state.gamesToDecline.length !== 0 } onHide={ () => this.hideDeclineModal() } centered>

                    <Modal.Header closeButton />

                    <Modal.Body>

                        <FormSelect value={ this.state.declineReason } onChange={ e => this.changeDeclineReason( e.target.value ) }>

                            <option value=''>{ _( 'Bitte Grund wählen...' ) }</option>

                            { this.state.declineReasons && this.state.declineReasons.map( ( e, i ) => <option value={ e.Id } key={ i }>{ e.Name }</option> ) }

                        </FormSelect>

                        { this.state.gamesToDecline.length > 1 &&

                            <div className='border-top mt-3 pt-1 text-muted w-100'><Icon icon='info-circle' /> { _( 'Der angegebene Grund wird für alle ausgewählten Spiele übernommen.' ) }</div>

                        }

                    </Modal.Body>

                    <Modal.Footer>

                        <Button onClick={ () => this.hideDeclineModal() } variant='secondary'>{ _( 'Abbrechen' ) }</Button>
                        <Button onClick={ () => this.declineGames() } disabled={ ! this.state.declineReason } variant='warning'>{ _( 'Absagen' ) }</Button>

                    </Modal.Footer>

                </Modal>

                <CalendarModal show={ this.state.calendarModalOpen } onHide={ () => this.toggleCalendarModal() } />

                <ToastContainer position='bottom-center' containerPosition='fixed'>

                    <Toast onClose={ () => this.dismissError() } show={ this.state.error !== null }>

                        <Toast.Header>

                            <div className='flex-grow-1'><Icon icon='exclamation-triangle' /> { _( 'Fehler' ) }</div>

                        </Toast.Header>

                        <Toast.Body>

                            <p>{ this.state.error }</p>

                        </Toast.Body>

                    </Toast>

                </ToastContainer>

            </>

        )

    }

}

export default withRouter( MySchedule )