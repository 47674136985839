import React from 'react'
import { PieChart } from 'react-minimal-pie-chart'

class RefereeInfoCard extends React.Component {

    render() {

        return (

            this.props.rating ?

                <PieChart
                    data={ [ { value: this.props.rating, color: '#0f2e5c' } ] }
                    label={ ( { dataEntry } ) => dataEntry.value }
                    labelPosition={ 0 }
                    labelStyle={ {
                        fontSize: '45px',
                        fill: '#0f2e5c',
                    } }
                    lineWidth={ 20 }
                    startAngle={ 270 }
                    style={ { maxHeight: '2em' } }
                    totalValue={ 100 }
                />

            :

                null

        )

    }

}

export default RefereeInfoCard