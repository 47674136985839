import React from 'react'
import { Icon, ajax, constants, deepCopy, fillDate, formatDate, formatTime, getDataUrl, hasPrivilege, translate as _ } from '@morawadigital/skynet-framework'
import { Button, Col, Dropdown, FloatingLabel, Form, Row, SplitButton, Toast, ToastContainer } from 'react-bootstrap'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import TreeSelect from '../controls/TreeSelect'
import { convertLeagues, createMessageFromGame, createOption, getDateRangePickerLocale } from '../../util'
import NotificationGameRow from '../elements/NotificationGameRow'
import { PRIV_LOAD_URGENT_CHANGES } from '../../util/constants'
import UrgentChanges from '../../containers/elements/UrgentChanges'
import CustomMessageModal from '../elements/CustomMessageModal'
import { getCustomer } from '../../util/customer'

class Notification extends React.Component {

    #customer

    constructor( props ) {

        super( props )

        this.state = {

            customMessageContent:    '',
            customMessageGames:      [],
            customMessageModalOpen:  false,
            customMessageRecipients: [],
            divisions:               null,
            federations:             null,
            games:                   null,
            hasLoadingError:         false,
            hasNotifyingError:       false,
            gamesNotified:           null,
            gamesNotifying:          [],
            gamesReleased:           null,
            isNotifying:             false,
            loadingGames:            false,
            selectedGamesNotified:   [],
            selectedGamesReleased:   [],

        }

        this.#customer = getCustomer()

    }

    componentDidMount() {

        this.load()

    }

    closeCustomMessageModal() {

        this.setState( {

            customMessageContent:    '',
            customMessageGames:      [],
            customMessageModalOpen:  false,
            customMessageRecipients: [],

        } )

    }

    composeMessage( gameIds, games ) {

        this.#customer.useCustomMessageForNotification ?

            this.openCustomMessage( gameIds, games )

        :

            this.props.onComposeMessage( createMessageFromGame( gameIds, games, this.props.user ) )

    }

    dismissLoadingError() {

        this.setState( { hasLoadingError: false } )

    }

    dismissNotifyingError() {

        this.setState( { hasNotifyingError: false } )

    }

    errorLoading() {

        this.setState( { hasLoadingError: true } )

    }

    errorNotifying() {

        this.setState( { gamesNotifying: [], hasNotifyingError: true } )

    }

    filterGame( game ) {

        if ( ! this.props.filterQuery ) {

            return true

        }

          let found       = false
        const filterQuery = this.props.filterQuery.toLowerCase()
        const values      = [

            formatDate( game.ScheduleDate, { year: 'none', weekday: 'short' } ),
            formatTime( game.ScheduleDate ),

        ]

        values.push( game.LeagueName )

        this.#customer.displayGameName && values.push( game.Name )

        game.AwayTeam && values.push( game.AwayTeam.Name, game.AwayTeam.ShortName )
        game.HomeTeam && values.push( game.HomeTeam.Name, game.HomeTeam.ShortName )

        if ( game.Location ) {

            if ( this.#customer.useLocationShortName ) {

                values.push( game.Location.Shortname )

            } else {

                                         values.push( game.Location.Name         )
                game.Location.Address && values.push( game.Location.Address.City )

            }

        }

        values.forEach( e => {

            if ( e && ( '' + e ).toLowerCase().includes( filterQuery ) ) {

                found = true

            }

        } )

        return found

    }

    handleAllGamesSelect( e, games, selectedGamesName ) {

        const selectedGames = []

        e.target.checked && games && games.forEach( e => selectedGames.push( e.Id ) )

        this.setState( { [ selectedGamesName ]: selectedGames } )

    }

    handleDateRangeChange( e, f ) {

        this.props.onDateRangeChange(

            f.startDate ?           f.startDate.toDate().toJSON() : null,
            f.endDate   ? fillDate( f.endDate.toDate() ).toJSON() : null

        )

        setTimeout( () => this.loadGames(), 200 )

    }

    handleDivisionsSelect( e ) {

        this.props.onDivisionsChange( e )

        setTimeout( () => this.loadGames(), 200 )

    }

    handleFilterQueryChange( e ) {

        this.props.onFilterQueryChange( e )

    }

    handleGameSelect( e, selectedGamesName ) {

          let selectedGames = deepCopy( this.state[ selectedGamesName ] )
        const gameId        = Number( e.target.value )
        const index         = selectedGames.indexOf( gameId )

        e.target.checked ? index === -1 && selectedGames.push( gameId ) : index !== -1 && selectedGames.splice( index, 1 )

        this.setState( { [ selectedGamesName ]:  selectedGames } )

    }

    isGameNotified( game ) {

        return game.MapRefereeGames && game.MapRefereeGames.find( e => e.Status > 0 )

    }

    load() {

        this.setState( { hasLoadingError: false }, () => setTimeout( () => {

            this.props.onToggleLoading( true )

            const itemsToLoad = [

                this.loadItems( { url: 'api/Schedule/getScheduleableLeagues', name: 'divisions',  fn: convertLeagues } ),
                this.loadItems( { url: 'api/Federation/Get',                  name: 'federations'                    } ),

            ]

            Promise
                .all( itemsToLoad )
                .catch( () => this.setState( { hasLoadingError: true } ) )
                .finally( () => {

                    this.props.onToggleLoading( false )

                    this.loadGames()

                } )

        }, 400 ) )

    }

    loadGames() {

        if ( ! this.props.dateRangeFrom || ! this.props.dateRangeTo || ! this.props.divisions.length ) {

            return

        }

        const success = games => {

            this.setState( { games }, () => this.splitGames() )

        }

        const data = {

            divIds:   { __arr: true, values: this.props.divisions },
            from:     this.props.dateRangeFrom.toJSON(),
            released: true,
            to:       this.props.dateRangeTo.toJSON(),
            token:    this.props.token,

        }

        this.setState( { hasLoadingError: false, loadingGames: true }, () => setTimeout( () => {

            this.props.onToggleLoading( true )

            ajax( getDataUrl( 'api/Schedule/getGames' ), data, { method: 'POST' } )
                .then( e => e.StatusId > 0 && e.Data ? success( e.Data ) : this.errorLoading() )
                .catch( () => this.errorLoading() )
                .finally( () => this.setState( { loadingGames: false }, () => this.props.onToggleLoading( false ) ) )

        }, 400 ) )

    }

    loadItems( options ) {

        return new Promise( ( resolve, reject ) => {

            ajax( getDataUrl( options.url ), { token: this.props.token }, { method: 'POST' } )
                .then( e => e.StatusId > 0 && e.Data ? this.setState( { [ options.name ]: options.fn ? options.fn( e.Data ) : e.Data }, resolve ) : reject() )
                .catch( reject )

        } )

    }

    notifyGames( gameIds, selectedGamesName ) {

        this.setState( { gamesNotifying: gameIds, hasNotifyingError: false, isNotifying: true }, () => setTimeout( () => {

            this.props.onToggleLoading( true )

            const success = updatedGames => {

                const selectedGames = this.state[ selectedGamesName ].filter( e => this.state.gamesNotifying.indexOf( e ) === -1 )
                const games         = this.state.games && deepCopy( this.state.games ).map( game => {

                    game            = updatedGames.find( updatedGame => updatedGame.Id === game.Id ) || game
                    game.isNotified = game.isNotified || this.state.gamesNotifying.indexOf( game.Id ) !== -1

                    return game

                } )

                this.setState( {

                    [ selectedGamesName ]: selectedGames,
                    gamesNotifying: [],

                    games,

                }, () => this.splitGames() )

            }

            ajax( getDataUrl( 'api/Schedule/notifyRefs' ), { token: this.props.token, notifiableGameIds: { __arr: true, values: this.state.gamesNotifying } }, { method: 'POST' } )
                .then( e => e.StatusId > 0 ? success( e.Data ) : this.errorNotifying() )
                .catch( () => this.errorNotifying() )
                .finally( () => this.setState( { isNotifying: false }, () => this.props.onToggleLoading( false ) ) )

        }, 400 ) )

    }

    openCustomMessage( gameIds, games ) {

        const customMessageGames      = []
        const customMessageRecipients = []

        gameIds.forEach( gameId => {

            const game = games.find( e => e.Id === gameId )

            if ( game ) {

                customMessageGames.push( game )

                game.MapRefereeGames && game.MapRefereeGames.forEach( e => e.RefereeMail && customMessageRecipients.findIndex( f => f.value === e.RefereeMail ) === -1 && customMessageRecipients.push( createOption( e.RefereeMail ) ) )

            }

        } )

        this.setState( { customMessageContent: '', customMessageGames, customMessageModalOpen: true, customMessageRecipients } )

    }

    splitGames() {

        const gamesNotified = []
        const gamesReleased = []

        this.state.games && this.state.games.forEach( game => this.isGameNotified( game ) ? gamesNotified.push( game ) : gamesReleased.push( game ) )

        this.setState( { gamesNotified, gamesReleased } )

    }

    renderGameList( options ) {

        const canSendMessage = hasPrivilege( this.props.user, constants.PRIV_SEND_MESSAGE ) || hasPrivilege( this.props.user, constants.PRIV_SEND_MESSAGE_WITH_READ_CONFIRMATION )
        const selectedGames  = this.state[ options.selectedGamesName ]
        const games          = options.games && options.games.filter( e => this.filterGame( e ) )

        return (

            <Row className='my-5'>

                <Col>

                    <h4>{ options.title }</h4>

                    <Form>

                        <Row className='align-items-center mb-3 px-3'>

                            <Col>

                                <Form.Check inline disabled={ ! games || ! games.length } label={ _( 'Alle' ) } onChange={ e => this.handleAllGamesSelect( e, games, options.selectedGamesName ) } checked={ games && games.length ? selectedGames.length === games.length : false } />

                            </Col>

                            <Col className='text-end'>

                                {

                                    canSendMessage ?

                                        <SplitButton
                                            align='end'
                                            disabled={ ! selectedGames.length || this.state.isNotifying }
                                            onClick={ () => this.notifyGames( selectedGames, options.selectedGamesName ) }
                                            title={ _( 'Auswahl versenden' ) }
                                        >

                                            <Dropdown.Item as='button' type='button' onClick={ () => this.composeMessage( selectedGames, games ) }>{ _( 'Eigene Nachricht' ) }</Dropdown.Item>

                                        </SplitButton>

                                    :

                                        <Button disabled={ ! selectedGames.length || this.state.isNotifying } onClick={ () => this.notifyGames( selectedGames, options.selectedGamesName ) }>{ _( 'Auswahl versenden' ) }</Button>

                                }

                            </Col>

                        </Row>

                    </Form>

                    {

                        this.state.loadingGames ?

                            Array.from( { length: 3 } ).map( ( e, i ) => <NotificationGameRow key={ i } /> )

                        : games && ! games.length ?

                            <div className='my-3 text-center'>{ _( 'Keine Spiele gefunden.' ) }</div>

                        : games ?

                            <div>

                                { games.map( ( game, i ) =>

                                    <NotificationGameRow
                                        canSendMessage={ canSendMessage }
                                        federations={ this.state.federations }
                                        game={ game }
                                        isNotifying={ this.state.gamesNotifying.indexOf( game.Id ) !== -1 }
                                        isSelected={ selectedGames.indexOf( game.Id ) !== -1 }
                                        key={ i }
                                        onNotifyGame={ e => this.notifyGames( [ e ], options.selectedGamesName ) }
                                        onComposeMessage={ e => this.composeMessage( [ e ], games ) }
                                        onSelectGame={ e => this.handleGameSelect( e, options.selectedGamesName ) }
                                    />

                                ) }

                            </div>

                        : ''

                    }

                </Col>

            </Row>

        )

    }

    render() {

        return (

            <>

                <div className='subheader'>

                    <h1 className='subheader-title'>

                        <Icon icon='envelope' className='subheader-icon' /> { _( 'Benachrichtigung' ) }

                    </h1>

                </div>

                { hasPrivilege( this.props.user, PRIV_LOAD_URGENT_CHANGES ) && <UrgentChanges federations={ this.state.federations } /> }

                <Form>

                    <Row className='mb-3'>

                        <Col className='mb-1' xs='6' md='3'>

                            <FloatingLabel label={ _( 'Liga' ) }>

                                <TreeSelect options={ this.state.divisions } initialValue={ this.props.divisions } onChange={ e => this.handleDivisionsSelect( e ) } />

                            </FloatingLabel>

                        </Col>

                        <Col className='mb-1' xs='6' md='3'>

                            <FloatingLabel label={ _( 'Zeitraum' ) }>

                                <DateRangePicker initialSettings={ {

                                        endDate:   this.props.dateRangeTo,
                                        locale:    getDateRangePickerLocale(),
                                        startDate: this.props.dateRangeFrom,

                                    } } onApply={ ( e, f ) => this.handleDateRangeChange( e, f ) }>

                                    <Form.Control />

                                </DateRangePicker>

                            </FloatingLabel>

                        </Col>

                        <Col className='mb-1' xs='12' md='6'>

                            <FloatingLabel label={ _( 'Spiele suchen' ) }>

                                <Form.Control value={ this.props.filterQuery } onChange={ e => this.handleFilterQueryChange( e.target.value ) } />

                            </FloatingLabel>

                        </Col>

                    </Row>

                </Form>

                { this.renderGameList( {

                    games:             this.state.gamesReleased,
                    selectedGamesName: 'selectedGamesReleased',
                    title:             _( 'Freigegeben' ),

                } ) }

                { this.renderGameList( {

                    games:             this.state.gamesNotified,
                    selectedGamesName: 'selectedGamesNotified',
                    title:             _( 'Bereits benachrichtigt' ),

                } ) }

                <CustomMessageModal
                    content={ this.state.customMessageContent }
                    games={ this.state.customMessageGames }
                    onContentChange={ customMessageContent => this.setState( { customMessageContent } ) }
                    onRecipientsChange={ e => this.setState( { customMessageRecipients: e.target.value } ) }
                    onHide={ () => this.closeCustomMessageModal() }
                    recipients={ this.state.customMessageRecipients }
                    show={ this.state.customMessageModalOpen }
                    token={ this.props.token }
                />


                <ToastContainer position='bottom-center' containerPosition='fixed'>

                    <Toast onClose={ () => this.dismissLoadingError() } show={ this.state.hasLoadingError }>

                        <Toast.Header>

                            <div className='flex-grow-1'><Icon icon='exclamation-triangle' /> { _( 'Fehler' ) }</div>

                        </Toast.Header>

                        <Toast.Body>

                            <p>{ _( 'Daten konnten nicht geladen werden.' ) }</p>

                            <Button onClick={ () => this.load() } variant='secondary'>{ _( 'Erneut versuchen' ) }</Button>

                        </Toast.Body>

                    </Toast>

                    <Toast onClose={ () => this.dismissNotifyingError() } show={ this.state.hasNotifyingError }>

                        <Toast.Header>

                            <div className='flex-grow-1'><Icon icon='exclamation-triangle' /> { _( 'Fehler' ) }</div>

                        </Toast.Header>

                        <Toast.Body>

                            <p>{ _( 'Benachrichtigung(en) konnte(n) nicht gesendet werden.' ) }</p>

                        </Toast.Body>

                    </Toast>

                </ToastContainer>

            </>

        )

    }

}

export default Notification