import React from 'react'
import { Badge, Button, Card, Col, Collapse, Dropdown, Placeholder, Row, SplitButton } from 'react-bootstrap'
import { withRouter } from 'react-router-dom'
import { Icon, translate as _ } from '@morawadigital/skynet-framework'
import { getStyle, getStyleIndex } from '../../util'

class LeagueRow extends React.Component {

    constructor( props ) {

        super( props )

        this.state = {

            detailsOpen: false,

        }

    }

    toggleDetails() {

        this.setState( { detailsOpen: ! this.state.detailsOpen } )

    }

    render() {

        return (

            <Card className={ 'mb-4 league-row' + ( this.props.league && this.props.league.Inactive ? ' inactive' : '' ) }>

                <Card.Body>

                    <Row className='align-items-center'>

                        <Col className={ this.props.league ? 'd-flex align-items-center' : '' } xs={ 12 } xl={ 8 }>

                            {

                                this.props.league ?

                                    <div className='fs-5'>

                                        { this.props.league.Source && <Badge pill className='me-2' bg={ getStyle( getStyleIndex( this.props.league.Source.Name ) ) }>{ this.props.league.Source.Name }</Badge> }

                                        { this.props.league.Name }

                                        <span className='text-muted ms-1'>{ this.props.league.Season }</span>

                                    </div>

                                :

                                    <Placeholder animation='wave'><Placeholder xs={ 6 } /></Placeholder>

                            }

                        </Col>

                        <Col className='text-end' xl={ 4 }>

                            {

                                this.props.league ?

                                    <SplitButton
                                        align='end'
                                        onClick={ () => this.props.history.push( '/liga/' + this.props.league.Id ) }
                                        title={ _( 'Verwalten' ) }
                                        variant='secondary'
                                    >

                                        <Dropdown.Item as='button' type='button' onClick={ () => this.props.history.push( '/spiele/' + this.props.league.Id ) }>{ _( 'Spiele verwalten' ) }</Dropdown.Item>

                                    </SplitButton>

                                :

                                    <Placeholder.Button variant='secondary' xs={ 3 } />

                            }

                        </Col>

                    </Row>

                    <Collapse in={ this.state.detailsOpen }>

                        <div>

                            <div className='my-5'>

                                {

                                    this.props.teams && this.props.teams.length ?

                                        this.props.teams.map( ( e, i ) => <Badge className='me-1 mb-1' key={ i } pill>{ e.Name }</Badge> )

                                    :

                                        <div>{ _( 'Keine Teams vorhanden.' ) }</div>

                                }

                            </div>

                        </div>

                    </Collapse>

                    <Button variant='outline-secondary' size='sm' className={ 'rounded-circle card-details-toggle' + ( this.state.detailsOpen ? ' open' : '' ) } onClick={ () => this.toggleDetails() }><Icon icon='chevron-down' /></Button>

                </Card.Body>

            </Card>

        )

    }

}

export default withRouter( LeagueRow )