import React from 'react'
import CreatableSelect from 'react-select'
import { createOption } from '../../util'

class MultiTextInput extends React.Component {

    #delimiters

    constructor( props ) {

        super( props )

        this.state = {

            inputValue: '',

        }

        this.#delimiters = [ 'Enter', 'Tab', ',', ';', ' ' ]

    }

    addValue() {

        const inputValue = this.state.inputValue

        this.setState( { inputValue: '' }, () => ! this.props.value.find( e => e.value === inputValue ) && this.props.onChange( { target: { name: this.props.name, value: [ ...this.props.value, createOption( inputValue ) ] } } ) )

    }

    handleBlur() {

        this.state.inputValue && this.addValue()

    }

    handleChange( value ) {

        this.props.onChange( { target: { name: this.props.name, value } } )

    }

    handleInputChange( inputValue ) {

        this.setState( { inputValue } )

    }

    handleKeyDown( e ) {

        if ( this.state.inputValue && this.#delimiters.includes( e.key ) ) {

            this.addValue()

            e.preventDefault()

        }

    }

    render() {

        return (

            <CreatableSelect
                className='multi-text-input'
                components={ { DropdownIndicator: null } }
                isDisabled={ this.props.disabled }
                inputValue={ this.state.inputValue }
                isClearable
                isMulti
                menuIsOpen={ false }
                onBlur={ () => this.handleBlur() }
                onChange={ e => this.handleChange( e ) }
                onInputChange={ e => this.handleInputChange( e ) }
                onKeyDown={ e => this.handleKeyDown( e ) }
                placeholder=''
                value={ this.props.value }
            />

        )

    }

}

export default MultiTextInput