import { Icon, ajx, ajxs, deepCopy, translate as _ } from '@morawadigital/skynet-framework'
import React from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { Link, withRouter } from 'react-router-dom'
import GameRow from '../elements/GameRow'

class Games extends React.Component {

    constructor( props ) {

        super( props )

        this.state = {

            games:         null,
            gamesDeleting: [],
            deleting:      false,
            league:        null,
            loadingGames:  false,
            selectedGames: [],

        }

    }

    componentDidMount() {

        this.load()

    }

    deleteGames( gameIds ) {

        if ( ! window.confirm( _( 'Spiel(e) löschen?' ) ) ) {

            return

        }

        this.setState( { gamesDeleting: gameIds, deleting: true }, () =>

            ajx( {

                complete:      () => this.setState( { deleting: false } ),
                data:          { ids: { __arr: true, values: this.state.gamesDeleting } },
                error:         _( 'Spiel(e) konnte(n) nicht gelöscht werden.' ),
                timeout:       400,
                toggleLoading: true,
                url:           'api/Game/Delete',

                success: () => {

                    const games         = deepCopy( this.state.games ).map( e => { return { ...e, isDeleted: e.isDeleted || this.state.gamesDeleting.indexOf( e.Id ) !== -1 } } )
                    const selectedGames = this.state.selectedGames.filter( e => this.state.gamesDeleting.indexOf( e ) === -1 )

                    this.setState( {

                        gamesDeleting: [],

                        games,
                        selectedGames,

                    } )

                }

            } )

        )

    }

    handleAllGamesSelect( e ) {

        const selectedGames = []

        e.target.checked && this.state.games && this.state.games.forEach( e => selectedGames.push( e.Id ) )

        this.setState( { selectedGames } )

    }

    handleGameSelect( e ) {

          let selectedGames = deepCopy( this.state.selectedGames )
        const gameId        = Number( e.target.value )
        const index         = selectedGames.indexOf( gameId )

        e.target.checked ? index === -1 && selectedGames.push( gameId ) : index !== -1 && selectedGames.splice( index, 1 )

        this.setState( { selectedGames } )

    }

    load() {

        this.setState( { loadingGames: true }, () =>

            ajxs( {

                complete:      () => this.setState( { loadingGames: false } ),
                timeout:       400,
                toggleLoading: true,

            }, [

                { data: { leagueId: this.props.match.params.leagueId },               success: e => this.setState( { games:  e } ), url: 'api/League/GetGameToLeague' },
                { data: { id:       this.props.match.params.leagueId }, single: true, success: e => this.setState( { league: e } ), url: 'api/League/Get'             },

            ] )

        )

    }

    render() {

        return (

            <>

                <div className='subheader'>

                    <h1 className='subheader-title'>

                        <Icon icon='play' className='subheader-icon' /> { _( 'Spiele' ) } { this.state.league && <span className='fw-300'>{ this.state.league.Name }</span> }

                    </h1>

                    <Link to='/ligaverwaltung' className='fs-3 ms-auto pe-3' role='button' title={ _( 'Schließen' ) }><Icon icon='times' /></Link>

                </div>

                <Form>

                    <Row className='align-items-center mb-3 px-3'>

                        <Col className='mb-1'>

                            <Form.Check inline disabled={ ! this.state.games || ! this.state.games.length } label={ _( 'Alle' ) } onChange={ e => this.handleAllGamesSelect( e ) } checked={ this.state.games && this.state.games.length ? this.state.selectedGames.length === this.state.games.length : false } />

                        </Col>

                        <Col className='mb-1 text-end'>

                            <Button onClick={ () => this.deleteGames( this.state.selectedGames ) } disabled={ ! this.state.selectedGames.length || this.state.deleting }>{ _( 'Auswahl löschen' ) }</Button>

                        </Col>

                    </Row>

                </Form>

                {

                    this.state.loadingGames ?

                        Array.from( { length: 3 } ).map( ( e, i ) => <GameRow key={ i } /> )

                    : this.state.games && ! this.state.games.length ?

                        <div className='my-3 text-center'>{ _( 'Keine Spiele gefunden.' ) }</div>

                    : this.state.games ?

                        <div>

                            { this.state.games.map( ( game, i ) =>

                                <GameRow
                                    deleting={ this.state.gamesDeleting.indexOf( game.Id ) !== -1 }
                                    game={ game }
                                    isSelected={ this.state.selectedGames.indexOf( game.Id ) !== -1 }
                                    key={ i }
                                    onDeleteGame={ e => this.deleteGames( [ e ] ) }
                                    onSelectGame={ e => this.handleGameSelect( e ) }
                                />

                            ) }

                        </div>

                    : ''

                }

            </>

        )

    }

}

export default withRouter( Games )