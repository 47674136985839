import React from 'react'
import { ajax, getDataUrl, Icon, fillDate, translate as _ } from '@morawadigital/skynet-framework'
import { withRouter } from 'react-router-dom'
import { Button, Col, FloatingLabel, Form, Row, Toast, ToastContainer } from 'react-bootstrap'
import InvoiceRow from '../elements/InvoiceRow'
import { getCustomerKey } from '../../util/customer'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import { getDateRangePickerLocale } from '../../util'

class InvoiceManagement extends React.Component {

    constructor( props ) {

        super( props )

        this.state = {

            hasLoadingError: false,
            invoices:        null,

        }

    }

    componentDidMount() {

        this.load()

    }

    dismissLoadingError() {

        this.setState( { hasLoadingError: false } )

    }

    errorLoading() {

        this.setState( { hasLoadingError: true } )

    }

    filterInvoice( invoice ) {

        if ( ! this.props.filterQuery ) {

            return true

        }

          let found       = false
        const filterQuery = this.props.filterQuery.toLowerCase()
        const values      = [

            invoice.Name,
            invoice.GameName,
            invoice.GameCity,

        ]

        values.forEach( e => {

            if ( e && ( '' + e ).toLowerCase().includes( filterQuery ) ) {

                found = true

            }

        } )

        return found

    }

    handleDateRangeChange( e, f ) {

        this.props.onDateRangeChange(

            f.startDate ?           f.startDate.toDate().toJSON() : null,
            f.endDate   ? fillDate( f.endDate.toDate() ).toJSON() : null

        )

        setTimeout( () => this.load(), 200 )

    }

    load() {

        this.setState( { hasLoadingError: false }, () => setTimeout( () => {

            this.props.onToggleLoading( true )

            const data = {

                customer: getCustomerKey(),
                from:     this.props.dateRangeFrom.toJSON(),
                to:       this.props.dateRangeTo.toJSON(),
                token:    this.props.token,

            }

            ajax( getDataUrl( 'api/Invoice/Get' ), data, { method: 'POST' } )
                .then( e => e.StatusId > 0 && e.Data ? this.setState( { invoices: e.Data } ) : this.errorLoading() )
                .catch( () => this.errorLoading() )
                .finally( () => this.props.onToggleLoading( false ) )

        }, 400 ) )

    }

    render() {

        const invoices = this.state.invoices ? this.state.invoices.filter( e => this.filterInvoice( e ) ) : null

        return (

            <>

                <div className='subheader'>

                    <h1 className='subheader-title'>

                        <Icon icon='file-invoice' className='subheader-icon' /> { _( 'Verrechnung' ) }

                    </h1>

                </div>

                <Row className='align-items-center mb-3 px-3'>

                    <Col xs={ 12 } sm={ 6 } xl={ 5 } xxl={ 4 } className='mb-1'>

                        <FloatingLabel label={ _( 'Zeitraum' ) }>

                            <DateRangePicker initialSettings={ {

                                endDate:   this.props.dateRangeTo,
                                locale:    getDateRangePickerLocale(),
                                startDate: this.props.dateRangeFrom,

                            } } onApply={ ( e, f ) => this.handleDateRangeChange( e, f ) }>

                                    <Form.Control />

                            </DateRangePicker>

                        </FloatingLabel>

                    </Col>

                    <Col xs={ 12 } sm={ 6 } xl={ 5 } xxl={ 4 } className='ms-auto mb-1'>

                        <Form.Control
                            className='floating-label-height'
                            onChange={ e => this.props.onFilterQueryChange( e.target.value ) }
                            placeholder={ _( 'Rechnungen suchen' ) }
                            size='lg'
                            value={ this.props.filterQuery }
                        />

                    </Col>

                </Row>

                <Row className='my-5'>

                    <Col>

                        {

                            this.props.loading ?

                                Array.from( { length: 3 } ).map( ( e, i ) => <InvoiceRow key={ i } /> )

                            : invoices && ! invoices.length ?

                                <div className='my-3 text-center'>{ _( 'Keine Einträge gefunden.' ) }</div>

                            : invoices ?

                                invoices.map( ( e, i ) => <InvoiceRow invoice={ e } key={ i } /> )

                            : ''

                        }

                    </Col>

                </Row>

                <ToastContainer position='bottom-center' containerPosition='fixed'>

                    <Toast onClose={ () => this.dismissLoadingError() } show={ this.state.hasLoadingError }>

                        <Toast.Header>

                            <div className='flex-grow-1'><Icon icon='exclamation-triangle' /> { _( 'Fehler' ) }</div>

                        </Toast.Header>

                        <Toast.Body>

                            <p>{ _( 'Daten konnten nicht geladen werden.' ) }</p>

                            <Button onClick={ () => this.load() } variant='secondary'>{ _( 'Erneut versuchen' ) }</Button>

                        </Toast.Body>

                    </Toast>

                </ToastContainer>

            </>

        )

    }

}

export default withRouter( InvoiceManagement )