import { friendlyFormatIBAN } from 'ibantools'
import { parseJSON } from '@morawadigital/skynet-framework'
import { convertToOptions } from '../util'
import DataTransferObject from './DataTransferObject'

class RefereeDto extends DataTransferObject {

    constructor( data ) {

        super( data )

        this.data.rating                          = this.data.Rating

        this.data.image                           = ( 'Image'                        in this.data ) ? this.data.Image                        : null
        this.data.internationalLinespersonLicense = ( 'InternationalLinesmenLicense' in this.data ) ? this.data.InternationalLinesmenLicense : null
        this.data.internationalRefereeLicense     = ( 'InternationalRefereeLicense'  in this.data ) ? this.data.InternationalRefereeLicense  : null
        this.data.jacketSize                      = ( 'Jacket'                       in this.data ) ? this.data.Jacket                       : null
        this.data.headSize                        = ( 'HeadSize'                     in this.data ) ? this.data.HeadSize                     : null
        this.data.mainFedRefSince                 = ( 'MainRefSince'                 in this.data ) ? this.data.MainRefSince                 : null
        this.data.poloSize                        = ( 'Polo'                         in this.data ) ? this.data.Polo                         : null
        this.data.pressImage                      = ( 'PressImage'                   in this.data ) ? this.data.PressImage                   : null
        this.data.refereeJerseySize               = ( 'RefereeJersey'                in this.data ) ? this.data.RefereeJersey                : null
        this.data.refereePantsSize                = ( 'RefereePants'                 in this.data ) ? this.data.RefereePants                 : null
        this.data.shoeSize                        = ( 'ShoeSize'                     in this.data ) ? this.data.ShoeSize                     : null
        this.data.subFedRefSince                  = ( 'SubFedRefSince'               in this.data ) ? this.data.SubFedRefSince               : null
        this.data.trainingJacketSize              = ( 'TrainingsJacket'              in this.data ) ? this.data.TrainingsJacket              : null
        this.data.trainingPantsSize               = ( 'TrainingsPants'               in this.data ) ? this.data.TrainingsPants               : null

        this.data.id                              = this.data.Id                                                                          || 0

        this.data.internationalCoach              = this.data.InternationalCoach                                                          || false
        this.data.isPaused                        = this.data.Paused                                                                      || false
        this.data.isTrainee                       = this.data.Trainee                                                                     || false
        this.data.isTaxable                       = this.data.Taxable                                                                     || false

        this.data.accountEmail                    = this.data.AccountMail                                                                 || ''
        this.data.firstname                       = this.data.Firstname                                                                   || ''
        this.data.lastname                        = this.data.Lastname                                                                    || ''
        this.data.leagueManagerNote               = this.data.LeagueManagerNote                                                           || ''
        this.data.nationalFederation              = this.data.NationalFederation                                                          || ''
        this.data.passNumber                      = this.data.PassNr                                                                      || ''
        this.data.refereeNote                     = this.data.RefereeNote                                                                 || ''
        this.data.bic                             = this.data.BIC                                                                         || ''

        this.data.absences                        = this.data.Absences                                                                    || []
        this.data.email                           = convertToOptions( this.data.Mails        ? parseJSON( this.data.Mails        ) : [] ) || []
        this.data.leaguePermissions               = this.data.MapLeagueReferees                                                           || []
        this.data.phone                           = convertToOptions( this.data.PhoneNumbers ? parseJSON( this.data.PhoneNumbers ) : [] ) || []
        this.data.refereeBans                     = this.data.RefereeBans                                                                 || []
        this.data.refereeRelations                = this.data.RefereeRelations                                                            || []

        this.data.iban                            = this.data.IBAN                                      ? friendlyFormatIBAN( this.data.IBAN )  : ''
        this.data.addressId                       = this.data.Address                                   ? this.data.Address.Id                  : null
        this.data.clubId                          = this.data.Club                                      ? this.data.Club.Id                     : null
        this.data.federationId                    = this.data.Federation                                ? this.data.Federation.Id               : null
        this.data.priceRating                     = this.data.PriceRating                               ? this.data.PriceRating.Id              : null
        this.data.priceRegion                     = this.data.PriceRegion                               ? this.data.PriceRegion.Id              : null

        this.data.city                            = this.data.Address                                   ? this.data.Address.City       || ''    : ''
        this.data.houseNumber                     = this.data.Address                                   ? this.data.Address.HouseNo    || ''    : ''
        this.data.postalCode                      = this.data.Address                                   ? this.data.Address.PostalCode || ''    : ''
        this.data.street                          = this.data.Address                                   ? this.data.Address.Street     || ''    : ''

        this.data.birthday                        = this.data.Birthday       ? this.data.Birthday.substring( 0, 10 )                                          : ''
        this.data.licenseTypes                    = this.data.LicenseTypes   ? this.data.LicenseTypes.map(   e => { return { value: e.Id, label: e.Name } } ) : []
        this.data.roles                           = this.data.Roles          ? this.data.Roles.map(          e => { return { value: e.Id, label: e.Name } } ) : []
        this.data.regions                         = this.data.RefereeRegions ? this.data.RefereeRegions.map( e => { return { value: e.Id, label: e.Name } } ) : []

    }

}

export default RefereeDto