import { connect } from 'react-redux'
import { storeActions } from '@morawadigital/skynet-framework'
import FeedbackForm from '../../components/elements/FeedbackForm'

const mapStateToProps = state => {

    return {

        loading: state.app.loading,

    }

}

const mapDispatchToProps = dispatch => {

    return {

        onToggleLoading: loading => dispatch( storeActions.app.toggleLoading( loading ) ),

    }

}

export default connect( mapStateToProps, mapDispatchToProps )( FeedbackForm )