import React from 'react'
import { getRefereeFullName, getRefereeShortName } from '../../util'
import { refereeCategories } from '../../util/enum'

class RefereeBadge extends React.Component {

    render() {

        const classes = [ 'referee-badge' ]

        this.props.active     && classes.push( 'active'             )
        this.props.empty      && classes.push( 'empty'              )
        this.props.low        && classes.push( 'low'                )
        this.props.selectable && classes.push( 'selectable'         )
        this.props.className  && classes.push( this.props.className )

        if ( this.props.referee ) {

            if ( 'classification' in this.props.referee ) classes.push( 'referee-badge-classification-' + refereeCategories[ this.props.referee.classification ] )
            if ( 'availability'   in this.props.referee ) classes.push( 'referee-badge-availability-'   + this.props.referee.availability                        )

        }

        return (

            <div
                className={ classes.join( ' ' ) }
                onClick={ () => this.props.onClick && this.props.onClick( this.props.referee, this.props.uid ) }
                onDoubleClick={ () => this.props.onDoubleClick && this.props.onDoubleClick( this.props.referee ) }
                title={ this.props.title ? this.props.title : getRefereeFullName( this.props.referee ) }
            >

                { this.props.displayName ? this.props.displayName : getRefereeShortName( this.props.referee ) }

                { this.props.label && <span className='referee-badge-label'>{ this.props.label }</span> }

            </div>

        )

    }

}

export default RefereeBadge