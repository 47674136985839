import { connect } from 'react-redux'
import UrgentChangeGameRow from '../../components/elements/UrgentChangeGameRow'
import { setDateRange, setDivisions, setScheduleGameFilterQuery } from '../../store/actions'

const mapDispatchToProps = dispatch => {

    return {

        onDateRangeChange:               ( from, to ) => dispatch( setDateRange(               from, to    ) ),
        onDivisionsChange:               divisions    => dispatch( setDivisions(               divisions   ) ),
        onScheduleGameFilterQueryChange: filterQuery  => dispatch( setScheduleGameFilterQuery( filterQuery ) ),

    }

}

export default connect( null, mapDispatchToProps )( UrgentChangeGameRow )