import React from 'react'
import { KEY_ENTER } from '../../util/constants'
import RefereeBadge from './RefereeBadge'
import { formatDateInput } from '@morawadigital/skynet-framework'

class RefereeAutoComplete extends React.Component {

    constructor( props ) {

        super( props )

        this.handleKeyUp = this.handleKeyUp.bind( this )

    }

    componentDidMount() {

        document.addEventListener( 'keyup', this.handleKeyUp )

    }

    componentWillUnmount() {

        document.removeEventListener( 'keyup', this.handleKeyUp )

    }

    getFilteredReferees() {

        const searchValue = this.props.value.toLowerCase()

        return this.props.value && this.props.referees ? this.props.referees.filter( e => e.Lastname.toLowerCase().indexOf( searchValue ) === 0 ) : []

    }

    handleKeyUp( e ) {

        if ( e.which === KEY_ENTER ) {

            const referees = this.getFilteredReferees()

            if ( referees.length === 1 ) {

                this.props.onRefereeDoubleClick( referees[ 0 ] )

                this.props.onRefereeClick( referees[ 0 ], 'assigned-referee-' + this.props.game.Id )

            }

        }

    }

    render() {

        const referees = this.getFilteredReferees()

        return (

            <div className='referee-auto-complete'>

                <div className='referee-auto-complete-value'>{ this.props.value }</div>

                { referees.length > 0 &&

                    <div className='referee-auto-complete-dropdown'>

                        { referees.map( ( referee, i ) => {

                            const refereeUid = 'available-referee-' + formatDateInput( this.props.game.ScheduleDate ) + '-' + referee.Id

                            return (

                                <div key={ i } className='badge-margin' onClick={ e => e.stopPropagation() }>

                                    <RefereeBadge
                                        active={ this.props.activeReferee && this.props.activeReferee.referee.Id === referee.Id && this.props.activeReferee.uid === refereeUid }
                                        className={ referee.classification === 'none' ? 'outline' : '' }
                                        onClick={ ( referee, uid ) => this.props.onRefereeClick( referee, uid ) }
                                        onDoubleClick={ referee => this.props.onRefereeDoubleClick( referee ) }
                                        referee={ referee }
                                        selectable={ true }
                                        uid={ refereeUid }
                                    />

                                </div>

                            )

                        } ) }

                    </div>

                }

            </div>

        )

    }

}

export default RefereeAutoComplete