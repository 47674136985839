import { Icon, avatars, parseJSON } from '@morawadigital/skynet-framework'
import React from 'react'
import { Badge, Card } from 'react-bootstrap'
import { getRefereeFullName } from '../../util'
import Lightbox from './Lightbox'

class RefereeBusinessCard extends React.Component {

    constructor( props ) {

        super( props )

        this.state = {

            lightboxOpen: false,

        }

    }

    toggleLightbox() {

        this.setState( { lightboxOpen: ! this.state.lightboxOpen } )

    }

    render() {

        const allEmailAddressses = []
        const phoneNumbers       = parseJSON( this.props.referee.PhoneNumbers )
        const emailAddresses     = parseJSON( this.props.referee.Mails        )

        this.props.referee.AccountMail && allEmailAddressses.push( this.props.referee.AccountMail )
        emailAddresses                 && allEmailAddressses.push( ...emailAddresses )

        return (

            <>

                <Card className='referee-business-card m-2'>

                    <Card.Body>

                        <div className='d-flex align-items-center'>

                            <div className='w-100'>

                                <div className='fs-5 fw-400'>{ getRefereeFullName( this.props.referee ) }</div>

                                { this.props.referee.assignment && <div>{ this.props.referee.assignment.ShortName }</div> }

                            </div>

                            <div className='flex-shrink-1'>

                                <span className={ 'position-relative d-inline-block' + ( this.props.referee.PressImage ? ' cursor-pointer' : '' ) } onClick={ this.props.referee.PressImage ? () => this.toggleLightbox() : null }>

                                    <img src={ this.props.referee.Image || avatars.male } alt='' className='profile-image rounded-circle border' />

                                    { this.props.referee.PressImage && <Icon icon='camera' className='image-icon text-primary' /> }

                                </span>

                            </div>

                        </div>

                        { ! this.props.hideContactInfo && this.props.referee.Address &&

                            <div className='my-3'>

                                { this.props.referee.Address.Street && <div>{ this.props.referee.Address.Street } { this.props.referee.Address.HouseNo }</div> }

                                { ( this.props.referee.Address.PostalCode || this.props.referee.Address.City) && <div>{ this.props.referee.Address.PostalCode } { this.props.referee.Address.City }</div> }

                            </div>

                        }

                        { ! this.props.hideContactInfo && phoneNumbers && phoneNumbers.length > 0 &&

                            <div>

                                <Icon icon='phone' className='me-2' />

                                { phoneNumbers.map( ( e, i ) => <Badge key={ i } bg='dark' className='me-1' pill><a href={ 'tel:' + e }>{ e }</a></Badge> ) }

                            </div>

                        }

                        { ! this.props.hideContactInfo && allEmailAddressses.length > 0 &&

                            <div>

                                <Icon icon='envelope' className='me-2' />

                                { allEmailAddressses.map( ( e, i ) => <Badge key={ i } bg='dark' className='me-1' pill><a href={ 'mailto:' + e }>{ e }</a></Badge> ) }

                            </div>

                        }

                    </Card.Body>

                </Card>

                { this.props.referee.PressImage &&

                    <Lightbox show={ this.state.lightboxOpen } onClose={ () => this.toggleLightbox() }>

                        <img src={ this.props.referee.PressImage } alt='' className='shadow' />

                    </Lightbox>

                }

            </>

        )

    }

}

export default RefereeBusinessCard