import { connect } from 'react-redux'
import ReleaseGameRow from '../../components/elements/ReleaseGameRow'
import { setScheduleGameFilterQuery } from '../../store/actions'

const mapDispatchToProps = dispatch => {

    return {

        onScheduleGameFilterQueryChange: filterQuery => dispatch( setScheduleGameFilterQuery( filterQuery ) ),

    }

}

export default connect( null, mapDispatchToProps )( ReleaseGameRow )