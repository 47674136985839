import { connect } from 'react-redux'
import AvailableRefereesTools from '../../components/elements/AvailableRefereesTools'
import { setAvailableRefereesToolsValues } from '../../store/actions'
import { AVAILABLE_REFEREES_SORT_BY_OPTION_NAME } from '../../util/constants'

const mapStateToProps = state => {

    return {

        filterName:              state.ui.availableRefereesFilterName    || '',
        filterZipCode:           state.ui.availableRefereesFilterZipCode || '',
        sortBy:                  state.ui.availableRefereesSortBy        || AVAILABLE_REFEREES_SORT_BY_OPTION_NAME,

    }

}

const mapDispatchToProps = dispatch => {

    return {

        onChange: ( filterName, filterZipCode, sortBy ) => dispatch( setAvailableRefereesToolsValues( filterName, filterZipCode, sortBy ) ),

    }

}

export default connect( mapStateToProps, mapDispatchToProps )( AvailableRefereesTools )