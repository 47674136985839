import React from 'react'
import { Badge, Button, Col, Form, Modal, Row } from 'react-bootstrap'
import { Icon, translate as _ } from '@morawadigital/skynet-framework'

class MultiSelect extends React.Component {

    constructor( props ) {

        super( props )

        this.state = {

            activeItem:  null,
            focusedItem: null

        }

    }

    changeItem( e ) {

        this.setState( { activeItem: { ...this.state.activeItem, [ e.target.name ]: e.target.value } } )

    }

    createItem() {

        return this.props.onCreateItem ? this.props.onCreateItem() : {}

    }

    deleteItem( item ) {

        this.props.onDelete && this.props.onDelete( item )

    }

    focusItem( focusedItem ) {

        this.props.editable && ! this.props.disabled && ( ! focusedItem || focusedItem.editable ) && this.setState( { focusedItem } )

    }

    hideModal() {

        this.setState( { activeItem: null } )

    }

    openItem( activeItem ) {

        this.setState( { activeItem } )

    }

    saveItem() {

        const activeItem = this.state.activeItem

        this.setState( { activeItem: null, focusedItem: null }, () => this.props.onChange && this.props.onChange( activeItem ) )

    }

    renderField( field, i ) {

        return (

            <Form.Group as={ Row } className='mb-3' controlId={ field.name } key={ i }>

                <Form.Label column sm={ 3 }>{ field.label }</Form.Label>

                <Col sm={ 9 }>

                    <Form.Select value={ this.state.activeItem ? this.state.activeItem[ field.name ] : '' } name={ field.name } onChange={ e => this.changeItem( e ) }>

                        { field.options && field.options.map( ( option, i ) => <option value={ option.value } key={ i }>{ option.label }</option> ) }

                    </Form.Select>

                </Col>

            </Form.Group>

        )

    }

    renderItem( item, i ) {

        return (

            <Badge key={ i } bg={ item.bg || 'secondary' } className={ item.className || '' }>

                {

                    this.props.editable && ! this.props.disabled && this.state.focusedItem && this.state.focusedItem.editable && this.state.focusedItem.id === item.id ?

                        <div className='multi-select-controls'>

                            <span onClick={ () => this.openItem( item ) }>{ _( 'Bearbeiten' ) }</span>
                            <span> | </span>
                            <span onClick={ () => this.deleteItem( item ) }>{ _( 'Löschen' ) }</span>
                            <span onClick={ () => this.focusItem( null ) }><Icon icon='times' /></span>

                        </div>

                    :

                        <span
                            className={ this.props.editable && ! this.props.disabled && item.editable ? 'multi-select-display' : '' }
                            onClick={ () => this.focusItem( item ) }
                            title={ item.title || '' }
                        >{ item.label }</span>

                }

            </Badge>

        )

    }

    render() {

        const classes = [ 'form-control', 'multi-select' ]

        this.props.disabled   && classes.push( 'disabled'   )
        this.props.editable   && classes.push( 'editable'   )
        this.props.standalone && classes.push( 'standalone' )

        return (

            <>

                <div className={ classes.join( ' ' ) }>

                    { this.props.initialValue && this.props.initialValue.map( ( e, i ) => this.renderItem( e, i ) ) }

                    { this.props.editable && ! this.props.disabled &&

                        <Button variant='link' onClick={ () => this.openItem( this.createItem() ) }><Icon icon='circle-plus' /></Button>

                    }

                </div>

                <Modal show={ this.state.activeItem !== null } onHide={ () => this.hideModal() } centered>

                    <Modal.Header closeButton />

                    <Modal.Body>

                        { this.props.fields && this.props.fields.map( ( e, i ) => this.renderField( e, i ) ) }

                    </Modal.Body>

                    <Modal.Footer>

                        <Button onClick={ () => this.hideModal() } variant='secondary'>{ _( 'Abbrechen' ) }</Button>
                        <Button onClick={ () => this.saveItem() }>{ _( 'Speichern' ) }</Button>

                        <div className='border-top mt-3 pt-1 text-muted w-100'><Icon icon='info-circle' /> { _( 'Der Eintrag wird sofort gespeichert.' ) }</div>

                    </Modal.Footer>

                </Modal>

            </>

        )

    }

}

export default MultiSelect