import React from 'react'
import { ErrorBoundary, Groups, Icon, Login, MenuItem, Skynet, createDate, hasPrivilege, reloadPage } from '@morawadigital/skynet-framework'
import { Link, Route } from 'react-router-dom'
import packageJson from '../../../package.json'
import User from '../containers/pages/User'
import Users from '../containers/pages/Users'
import Schedule from '../containers/pages/Schedule'
import Release from '../containers/pages/Release'
import Notification from '../containers/pages/Notification'
import Evaluation from '../containers/pages/Evaluation'
import LeagueManagement from '../containers/pages/LeagueManagement'
import League from '../containers/pages/League'
import Team from '../containers/pages/Team'
import Dashboard from '../containers/pages/Dashboard'
import { isDevelopment } from '../util'
// import PWAInstaller from './PWAInstaller'
import { Button, Toast, ToastContainer } from 'react-bootstrap'
import Feedback from './pages/Feedback'
import FeedbackStandalone from './pages/FeedbackStandalone'
import { isAdmin } from '../util/permissions'
import MySchedule from '../containers/pages/MySchedule'
import PublicSchedule from '../containers/pages/PublicSchedule'
import Games from '../containers/pages/Games'
import CoachResult from '../containers/pages/CoachResult'
import { PRIV_LOAD_AVAILABLE_REFEREES, PRIV_LOAD_CONTACTS, PRIV_LOAD_EVALUATIONS, PRIV_LOAD_GROUPS, PRIV_LOAD_INVOICES, PRIV_LOAD_LEAGUES, PRIV_LOAD_MY_REFEREE, PRIV_LOAD_MY_SCHEDULE, PRIV_LOAD_REFEREES, PRIV_LOAD_REGIONS, PRIV_LOAD_RELEASE_GAMES, PRIV_LOAD_SCHEDULE, PRIV_LOAD_SUBFEDERATION_SCHEDULE, PRIV_LOAD_URGENT_CHANGES } from '../util/constants'
import { getCustomer } from '../util/customer'
import RegionManagement from '../containers/pages/RegionManagement'
import Region from '../containers/pages/Region'
import Invoice from '../containers/pages/Invoice'
import InvoiceManagement from '../containers/pages/InvoiceManagement'

class App extends React.Component {

    constructor( props ) {

        super( props )

        this.state = {

            appUpdateAvailable: false,
            updatingApp:        false,

        }

    }

    componentDidMount() {

        setTimeout( () => {

            if ( window.appUpdateAvailable ) {

                this.setState( { appUpdateAvailable: true } )

                setTimeout( () => this.setState( { appUpdateAvailable: false } ), 1000 * 60 )

            }

        }, 1000 * 5 )

    }

    hideAppUpdateNotification() {

        this.setState( { appUpdateAvailable: false } )

    }

    updateApp() {

        this.setState( { updatingApp: true }, () => {

            caches
                .keys()
                .then( keys => keys.forEach( c => caches.delete( c ) ) )
                .then( () => setTimeout( reloadPage, 1000 * 3 ) )

        } )

    }

    render() {

        const customer = getCustomer()
        let baseDataUrl

        if ( process.env.REACT_APP_BASE_DATA_URL ) {

            baseDataUrl = process.env.REACT_APP_BASE_DATA_URL

            if ( process.env.REACT_APP_BASE_DATA_PORT ) {

                baseDataUrl += ':' + process.env.REACT_APP_BASE_DATA_PORT + '/'

            }

        } else if ( isDevelopment() ) {

            baseDataUrl = customer.devBaseDataUrl

        } else {

            baseDataUrl = customer.baseDataUrl[ window.location.hostname ]

        }

        const conf = {

            firebaseConfig:              {

                apiKey:            'AIzaSyDSQoHoe0xzOF_MC-ildFewcc3z2GZzDGU',
                authDomain:        'morawa-skynet-push.firebaseapp.com',
                projectId:         'morawa-skynet-push',
                storageBucket:     'morawa-skynet-push.appspot.com',
                messagingSenderId: '721850682646',
                appId:             '1:721850682646:web:719b96d6ce195b36f9d437',
                measurementId:     'G-J9SFWPY19K',

            },
            footerLeft:                         createDate().getFullYear() + ' © Morawa Digital',
            footerRight:                        'easyRef v' + packageJson.version,
            loginHeaderRight:                   ! customer.disablePublicSchedule && <Link to='/' className='btn-link text-white'><Icon icon='angle-left' /> Zurück zur Startseite</Link>,
            logoImageUrl:                       customer.logoImageUrl,
            logoText:                           'easyRef',
            pageSidebarInfoCardBackgroundImage: customer.pageSidebarInfoCardBackgroundImage,
            passwordRecoveryHeaderRight:        ! customer.disablePublicSchedule && <Link to='/' className='btn-link text-white'><Icon icon='angle-left' /> Zurück zur Startseite</Link>,
            passwordResetHeaderRight:           ! customer.disablePublicSchedule && <Link to='/' className='btn-link text-white'><Icon icon='angle-left' /> Zurück zur Startseite</Link>,

            baseDataUrl,

        }

          let mainNavigationMenuItems = []
          let pageRoutes              = []
        const rootRoutes              = [ <Route path='/feedback/:assignmentId' key='feedback' children={ <FeedbackStandalone logoImageUrl={ conf.logoImageUrl } logoText={ conf.logoText } /> } /> ]

        if ( isAdmin( this.props.user ) ) {

            mainNavigationMenuItems.push( { url: '/dashboard', children: <><Icon fw icon='dashboard' /><span className='nav-link-text'>Dashboard</span></> } )

            pageRoutes.push( { path: '/dashboard', children: <Dashboard /> } )

        }

        if ( hasPrivilege( this.props.user, PRIV_LOAD_MY_SCHEDULE ) ) {

            mainNavigationMenuItems.push( { url: '/meine-einteilungen', children: <><Icon fw icon='calendar-alt' /><span className='nav-link-text'>Meine Einteilungen</span></> } )

            pageRoutes.push( { path: '/meine-einteilungen', children: <MySchedule /> } )

        }

        if ( hasPrivilege( this.props.user, PRIV_LOAD_SUBFEDERATION_SCHEDULE ) ) {

            mainNavigationMenuItems.push( { url: '/lev-einteilungen', children: <><Icon fw icon='calendar-alt' /><span className='nav-link-text'>LEV Einteilungen</span></> } )

            pageRoutes.push( { path: '/lev-einteilungen', children: <MySchedule isSubfederation /> } )

        }

        if ( hasPrivilege( this.props.user, PRIV_LOAD_SCHEDULE ) ) {

            mainNavigationMenuItems.push( { url: '/einteilung', className: this.props.hasScheduleLocalChanges ? 'flag' : '', children: <><Icon fw icon='calendar'  /><span className='nav-link-text'>Einteilung</span></> } )

            pageRoutes.push( { path: '/einteilung', children: <Schedule /> } )

        }

        if ( hasPrivilege( this.props.user, PRIV_LOAD_RELEASE_GAMES ) || hasPrivilege( this.props.user, PRIV_LOAD_URGENT_CHANGES ) ) {

            mainNavigationMenuItems.push( { url: '/freigabe', children: <><Icon fw icon='check' /><span className='nav-link-text'>Freigabe</span></> } )

            pageRoutes.push( { path: '/freigabe', children: <Release /> } )

        }

        if ( hasPrivilege( this.props.user, PRIV_LOAD_RELEASE_GAMES ) ) {

            mainNavigationMenuItems.push( { url: '/benachrichtigung', children: <><Icon fw icon='envelope' /><span className='nav-link-text'>Benachrichtigung</span></> } )

            pageRoutes.push( { path: '/benachrichtigung', children: <Notification /> } )

        }

        if ( hasPrivilege( this.props.user, PRIV_LOAD_EVALUATIONS ) ) {

            mainNavigationMenuItems.push( { url: '/evaluierung', children: <><Icon fw icon='list' /><span className='nav-link-text'>Evaluierung</span></> } )

            pageRoutes.push( { path: '/evaluierung/:coachResultId', children: <CoachResult /> } )
            pageRoutes.push( { path: '/evaluierung', children: <Evaluation /> } )

        }

        if ( hasPrivilege( this.props.user, PRIV_LOAD_AVAILABLE_REFEREES ) ) {

            mainNavigationMenuItems.push( { url: '/verfuegbare-schiedsrichter-innen', children: <><Icon fw icon='users' /><span className='nav-link-text'>Verfügbare Schiedsrichter:innen</span></> } )

            pageRoutes.push( { path: '/verfuegbare-schiedsrichter-innen', children: <Users availableReferees /> } )

        }

        if ( hasPrivilege( this.props.user, PRIV_LOAD_LEAGUES ) ) {

            mainNavigationMenuItems.push( { url: '/ligaverwaltung', children: <><Icon fw icon='diamond' /><span className='nav-link-text'>Ligaverwaltung</span></> } )

            pageRoutes.push( { path: '/ligaverwaltung',   children: <LeagueManagement /> } )
            pageRoutes.push( { path: '/liga/:leagueId',   children: <League           /> } )
            pageRoutes.push( { path: '/team/:teamId',     children: <Team             /> } )
            pageRoutes.push( { path: '/spiele/:leagueId', children: <Games            /> } )

        }

        if ( hasPrivilege( this.props.user, PRIV_LOAD_REGIONS ) ) {

            mainNavigationMenuItems.push( { url: '/regionsverwaltung', children: <><Icon fw icon='earth-europe' /><span className='nav-link-text'>Regionsverwaltung</span></> } )

            pageRoutes.push( { path: '/regionsverwaltung', children: <RegionManagement /> } )
            pageRoutes.push( { path: '/region/:regionId',  children: <Region           /> } )

        }

        if ( hasPrivilege( this.props.user, PRIV_LOAD_INVOICES ) ) {

            mainNavigationMenuItems.push( { url: '/verrechnung', children: <><Icon fw icon='file-invoice' /><span className='nav-link-text'>Verrechnung</span></> } )

            pageRoutes.push( { path: '/verrechnung/:invoiceId', children: <Invoice           /> } )
            pageRoutes.push( { path: '/verrechnung',            children: <InvoiceManagement /> } )

        }

        if ( hasPrivilege( this.props.user, PRIV_LOAD_MY_REFEREE ) ) {

            mainNavigationMenuItems.push( { url: '/meine-daten', children: <><Icon fw icon='user' /><span className='nav-link-text'>Meine Daten</span></> } )

            pageRoutes.push( { path: '/meine-daten', children: <User self /> } )

        }

        if ( hasPrivilege( this.props.user, PRIV_LOAD_REFEREES ) ) {

            mainNavigationMenuItems.push( { url: '/user', children: <><Icon fw icon='users' /><span className='nav-link-text'>Benutzer:innen</span></> } )

            pageRoutes.push( { path: '/user/:userId', children: <User showCloseButton={ true } /> } )
            pageRoutes.push( { path: '/user',         children: <Users                         /> } )

        }

        if ( hasPrivilege( this.props.user, PRIV_LOAD_CONTACTS ) ) {

            mainNavigationMenuItems.push( { url: '/kontakte', children: <><Icon fw icon='users' /><span className='nav-link-text'>Kontakte</span></> } )

            pageRoutes.push( { path: '/kontakte', children: <Users contacts /> } )

        }

        if ( hasPrivilege( this.props.user, PRIV_LOAD_GROUPS ) ) {

            mainNavigationMenuItems.push( { url: '/gruppen', children: <><Icon fw icon='users' /><span className='nav-link-text'>Gruppen</span></> } )

            pageRoutes.push( { path: '/gruppen', children: <Groups /> } )

        }

        pageRoutes.push( { path: '/feedback/:assignmentId', children: <Feedback /> } )

        if ( mainNavigationMenuItems.length ) {

            mainNavigationMenuItems[ 0 ].url = '/'

        }

        if ( pageRoutes.length ) {

            const firstPageRoute = pageRoutes.shift()
            firstPageRoute.path  = '/'

            pageRoutes.push( firstPageRoute )

        }

        mainNavigationMenuItems = mainNavigationMenuItems.map( ( e, i ) => <MenuItem url={ e.url } key={ i } className={ e.className || '' }>{ e.children }</MenuItem> )
        pageRoutes              = pageRoutes.map( ( e, i ) => <Route path={ e.path } key={ i } children={ e.children } /> )

        conf.mainNavigationMenuItems = mainNavigationMenuItems
        conf.pageRoutes              = pageRoutes
        conf.rootRoutes              = rootRoutes
        conf.rootComponent           = customer.disablePublicSchedule ? <Login logoImageUrl={ conf.logoImageUrl } logoText={ conf.logoText } /> : <PublicSchedule headerRight={ <Link to={ '/login' } className='btn-link text-white'>Anmelden</Link> } logoImageUrl={ conf.logoImageUrl } logoText={ conf.logoText } />

        return (

            <ErrorBoundary>

                <Skynet { ...conf } />

                {/* <PWAInstaller /> */} {/* TODO Activate */}

                <ToastContainer position='bottom-center' containerPosition='fixed'>

                    <Toast show={ this.state.appUpdateAvailable } onClose={ () => this.hideAppUpdateNotification() }>

                        <Toast.Header>

                            <div className='flex-grow-1'>Update verfügbar</div>

                        </Toast.Header>

                        <Toast.Body>

                            <p>Eine neue App-Version ist verfügbar!</p>

                            <Button onClick={ () => this.updateApp() } disabled={ this.state.updatingApp }>{ this.state.updatingApp ? 'Installiere...' : 'Jetzt installieren' }</Button>

                        </Toast.Body>

                    </Toast>

                </ToastContainer>

            </ErrorBoundary>

        )

    }

}

export default App