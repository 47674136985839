import React from 'react'
import { translate as _, ajax, formatDate, getDataUrl, Icon } from '@morawadigital/skynet-framework'
import { Alert, Button, Col, Row } from 'react-bootstrap'
import { withRouter } from 'react-router-dom'

class FeedbackForm extends React.Component {

    constructor( props ) {

        super( props )

        this.state = {

            assignment:       null,
            hasAccepted:      false,
            hasDeclined:      false,
            hasLoadingError:  false,
            hasReplyingError: false,
            isAccepting:      false,
            isDeclining:      false,
            loading:          false,

        }

    }

    componentDidMount() {

        this.load()

    }

    accept() {

        this.setState( { isAccepting: true, hasReplyingError: false }, () => setTimeout( () => {

            this.props.onToggleLoading( true )

            ajax( getDataUrl( 'api/Referee/AcceptGame' ), { id: this.props.match.params.assignmentId } )
                .then( e => e.StatusId > 0 ? this.setState( { hasAccepted: true } ) : this.errorReplying() )
                .catch( () => this.errorReplying() )
                .finally( () => this.setState( { isAccepting: false }, () => this.props.onToggleLoading( false ) ) )

        }, 400 ) )

    }

    decline() {

        if ( ! window.confirm( _( 'Möchten Sie der Einteilung wirklich absagen?' ) ) ) {

            return

        }

        this.setState( { isDeclining: true, hasReplyingError: false }, () => setTimeout( () => {

            this.props.onToggleLoading( true )

            ajax( getDataUrl( 'api/Referee/DeclineGame' ), { id: this.props.match.params.assignmentId } )
                .then( e => e.StatusId > 0 ? this.setState( { hasDeclined: true } ) : this.errorReplying() )
                .catch( () => this.errorReplying() )
                .finally( () => this.setState( { isDeclining: false }, () => this.props.onToggleLoading( false ) ) )

        }, 400 ) )

    }

    errorLoading() {

        this.setState( { hasLoadingError: true } )

    }

    errorReplying() {

        this.setState( { hasReplyingError: true } )

    }

    load() {

        this.setState( { loading: true, hasLoadingError: false }, () => {

            this.props.onToggleLoading( true )

            ajax( getDataUrl( 'api/Referee/getGameInfo' ), { id: this.props.match.params.assignmentId }, { method: 'POST' } )
                .then( e => e.StatusId > 0 && e.Data && e.Data.length ? this.setState( { assignment: e.Data[ 0 ] } ) : this.errorLoading() )
                .catch( () => this.errorLoading() )
                .finally( () => this.setState( { loading: false }, () => this.props.onToggleLoading( false ) ) )

        } )

    }

    render() {

        const address = []

        if ( this.state.assignment && this.state.assignment.Game && this.state.assignment.Game.Location && this.state.assignment.Game.Location.Address ) {

            const addr = this.state.assignment.Game.Location.Address

            addr.Street     && address.push( addr.Street + ( addr.HouseNo ? ' ' + addr.HouseNo : '' ) )
            addr.PostalCode && address.push( addr.PostalCode                                          )
            addr.City       && address.push( addr.City                                                )

        }

        return (

            <>

                {

                    this.state.loading ?

                        <div className='my-5 text-center'>

                            <h4><Icon spin icon='spinner' /></h4>

                        </div>

                    : this.state.hasLoadingError || ! this.state.assignment ?

                        <div className='my-5 text-center'>

                            <Alert variant='warning'><Icon icon='exclamation-triangle' /> { _( 'Ein unbekannter Fehler ist aufgetreten.' ) }</Alert>

                        </div>

                    : this.state.hasAccepted ?

                        <div className='my-5 text-center text-success'>

                            <h4 className='my-5 fs-1'><Icon icon='check' /></h4>

                            <h4 className='fw-900'>{ _( 'Zugesagt' ) }</h4>

                        </div>

                    : this.state.hasDeclined ?

                        <div className='my-5 text-center text-warning'>

                            <h4 className='my-5 fs-1'><Icon icon='times' /></h4>

                            <h4 className='fw-900'>{ _( 'Abgesagt' ) }</h4>

                        </div>

                    :

                        <>

                            <h3>{ this.state.assignment.Referee ? _( 'Hallo' ) + ' ' + this.state.assignment.Referee.Firstname + ' ' + this.state.assignment.Referee.Lastname + '!' : _( 'Hallo!' ) }</h3>

                            <div className='fs-6 fw-300'>

                                <p className='mb-3'>{ this.state.assignment.Game ? _( 'Sie wurden für ein Spiel am' ) + ' ' + formatDate( this.state.assignment.Game.ScheduleDate, { weekday: 'short' } ) + ' eingeteilt:' : _( 'Sie wurden für ein Spiel eingeteilt:' ) }</p>

                                { address.length &&

                                    <Row className='my-3'>

                                        <Col xs={ 4 }>{ _( 'Adresse' ) }</Col>

                                        <Col>{ address.join( ', ' ) }</Col>

                                    </Row>

                                }

                                { this.state.assignment.Game && this.state.assignment.Game.HomeTeam &&

                                    <Row className='my-3'>

                                        <Col xs={ 4 }>{ _( 'Heimteam' ) }</Col>

                                        <Col>{ this.state.assignment.Game.HomeTeam.Name }</Col>

                                    </Row>

                                }

                                { this.state.assignment.Game && this.state.assignment.Game.AwayTeam &&

                                    <Row className='my-3'>

                                        <Col xs={ 4 }>{ _( 'Auswärtsteam' ) }</Col>

                                        <Col>{ this.state.assignment.Game.AwayTeam.Name }</Col>

                                    </Row>

                                }

                                <p className='mt-5'>{ _( 'Möchten Sie absagen oder zusagen?' ) }</p>

                            </div>

                            <fieldset disabled={ this.state.isAccepting || this.state.isDeclining }>

                                <Row className='my-5'>

                                    <Col>

                                        <div className='d-grid'>

                                            <Button onClick={ () => this.decline() } variant='warning' size='lg'>{ this.state.isDeclining ? <Icon spin icon='spinner' /> : _( 'Absagen' ) }</Button>

                                        </div>

                                    </Col>

                                    <Col>

                                        <div className='d-grid'>

                                            <Button onClick={ () => this.accept() } variant='success' size='lg'>{ this.state.isAccepting ? <Icon spin icon='spinner' /> : _( 'Zusagen' ) }</Button>

                                        </div>

                                    </Col>

                                </Row>

                            </fieldset>

                            { this.state.hasReplyingError && <Alert variant='warning'>{ _( 'Ein unbekannter Fehler ist aufgetreten.' ) }</Alert> }

                        </>

                }

            </>

        )

    }

}

export default withRouter( FeedbackForm )